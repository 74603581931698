import React, { useState, useEffect } from "react";
import {
  Flex,
  Box,
  Heading,
  Text,
  Button,
  Grid,
  Center,
  VStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Textarea,
  useToast,
  IconButton,
  HStack,
  Checkbox,
  useRadio,
  useRadioGroup,
  Card,
  CardBody,
  Tag,
  TagLabel,
  TagCloseButton,
  Wrap,
  WrapItem,
  Input,
  Select,
} from "@chakra-ui/react";
import { MdOutlineDelete } from "react-icons/md";
import { AddIcon, EditIcon, CheckIcon } from "@chakra-ui/icons";
import { FaStar, FaStarHalfAlt, FaRegStar } from "react-icons/fa";
import axios from "axios";
import apiClient from "../../../api/axiosInstance"; // 생성한 Axios 인스턴스를 import
import { useNavigate, useLocation } from "react-router-dom";
import { whiten } from "@chakra-ui/theme-tools";
// 문제 유형별 색상 매핑
const typeColors = {
  "내용 전개 방식 파악": "red",
  "내용 전개 방식 파악(주제 통합)": "orange",
  "세부 내용 파악": "yellow",
  "세부 내용 파악(주제 통합)": "green",
  "세부 내용 추론": "teal",
  "중심 내용 파악": "blue",
  "중심 내용 추론": "cyan",
  "중심 내용 파악/추론": "purple",
  "생략된 내용 추론": "pink",
  "내용의 인과관계 파악": "linkedin",
  "구체적 사례 적용": "messenger",
  "글에 드러난 관점 비판": "whatsapp",
  "다른 견해와의 비교": "twitter",
  "단어의 의미 파악": "facebook",
  // 필요한 만큼 색상을 추가하세요
  //문학
  "운문 - <보기> 문항": "red",
  "운문 - 시어, 시구의 의미와 기능 파악": "orange",
  "운문 - 표현상 특징": "yellow",
  "운문 - 시구의 비교와 대조": "green",
  "운문 - 공간, 시간, 소재의 기능 파악": "teal",
  "운문 - 작품의 맥락 이해": "blue",
  "소설 - 발화의 내용 및 기능": "cyan",
  "소설 - <보기> 문항": "purple",
  "소설 - 구절의 의미 이해": "pink",
  "소설 - 인물의 심리, 태도 이해": "linkedin",
  "소설 - 작품 내용 이해": "messenger",
  "소설 - 서술상 특징": "whatsapp",
  "소설 - 공간, 시간, 소재의 기능 파악": "twitter",
  "소설 - 작품의 맥락 이해": "facebook",
};

const pastelColors = {
  red: "#ffadad",
  orange: "#ffd6a5",
  yellow: "#fdffb6",
  green: "#caffbf",
  teal: "#9bf6ff",
  blue: "#a0c4ff",
  cyan: "#a0c4ff",
  purple: "#bdb2ff",
  pink: "#ffc6ff",
  linkedin: "#ffadad",
  messenger: "#ffd6a5",
  whatsapp: "#fdffb6",
  twitter: "#caffbf",
  facebook: "#9bf6ff",
  // 필요한 만큼 색상을 추가하세요
};

// 문제 유형 데이터 예시
const typeData = [
  {
    type: "내용 전개 방식 파악",
    policy:
      "하나의 선지에 정보가 2개 이상 제시됩니다. 이를 위해 연결 어미를 사용하여 2개의 절을 이어 붙인 문장의 형태를 주로 사용합니다.",
    example:
      "1. 윗글의 내용 전개 방식으로 가장 적절한 것은?\r\n① 대상의 개념과 장단점을 제시하고 보완책을 소개한다.\r\n② 유사한 원리들을 분석하고 이를 하나의 이론으로 통합한다.\r\n③ 대립하는 유형을 들어 이론적 근거의 변천 과정을 설명한다.\r\n④ 가설을 세우고 그에 대해 현실적인 사례를 들어 가며 검토한다.\r\n⑤ 문제 상황의 근본 원인을 진단하고 해결책에 대한 상반된 입장을 해설한다.",
  },
  {
    type: "내용 전개 방식 파악(주제 통합)",
    policy:
      "(가)와 (나)에 대한 설명으로 / (가)와 (나)의 내용 전개 방식으로 적절한 것은? 과 같은 발문을 씁니다.",
    example:
      "1. (가)와 (나)에 대한 설명으로 가장 적절한 것은?\r\n① (가)는 유서의 유형을 분류하였고, (나)는 유서의 분류 기준과 적절성 여부를 평가하였다.\r\n② (가)는 유서의 개념과 유용성을 소개하였고, (나)는 국가별 유서의 변천 과정을 설명하였다.\r\n③ (가)는 유서의 기원에 대한 다양한 학설을 검토하였고, (나)는 유서 편찬자들 간의 견해 차이를 분석하였다.\r\n④ (가)는 유서의 특성과 의의를 설명하였고, (나)는 유서 편찬 에서 특정 학문의 수용 양상을 시기별로 소개하였다.\r\n⑤ (가)는 유서에 대한 평가가 시대별로 달라진 원인을 분석 하였고, (나)는 역사적으로 대표적인 유서의 특징을 제시하였다.",
  },
  {
    type: "세부 내용 파악",
    policy:
      "난도가 가장 낮은 유형으로, 지문에서 제시된 정보의 표현을 약간 바꾸어 선지를 구성합니다.",
    example:
      "1. 윗글의 내용과 일치하지 않는 것은?\r\n① 글을 선정하는 과정에서 글을 평가하는 것은 중요하다.\r\n② 여러 글 읽기에서 정보를 연결하는 것은 문제 해결에 유용한 방법이 될 수 있다.\r\n③ 궁금증을 해소하기 위한 읽기에서 글의 의미를 재구성하는 전략이 사용될 수 있다.\r\n④ 여러 글에서 필요한 정보를 추출하는 과정은 문제를 해결하기 위한 읽기 목적과 관련된다.\r\n⑤ 필요한 정보를 한 편의 글에서 얻지 못할 때는 다른 글을 찾기보다 그 글을 반복해서 읽는다.",
  },
  {
    type: "세부 내용 파악(주제 통합)",
    policy:
      "2개의 글을 엮은 주제 통합 지문에서는 (가)와 (나) 중 하나에 대해서만 묻기도 합니다.",
    example:
      "1. (가)에 제시된 한비자의 견해로 적절하지 않은 것은?\r\n① 사건의 시비에 따라 달라지는 도에 근거하여 법이 제정되어야 한다.\r\n② 인간은 무엇을 가지거나 누리고자 하는 마음에서 벗어날 수 없다.\r\n③ 도는 고정된 모습 없이 때와 형편에 따라 변화하며 영원히 존재한다.\r\n④ 인간 사회의 흥망성쇠는 사람이 도에 따라 올바르게 행하였는가의 여부에 좌우되는 것이다.\r\n⑤ 도는 만물의 근원이면서 동시에 현실 사회의 개별 사물과 사건에 내재한 법칙을 포괄하는 것이다.",
  },
  {
    type: "세부 내용 추론",
    policy: "세부 내용 파악 유형에 비해서는 난도가 높아야 합니다.",
    example:
      "1. 윗글을 이해한 내용으로 적절하지 않은 것은?\r\n① 데이터가 수치로 구성되지 않아도 최빈값을 구할 수 있다.\r\n② 데이터의 특징이 언제나 하나의 수치로 나타나는 것은 아니다.\r\n③ 데이터가 정상적으로 수집되었다면 이상치가 존재하지 않는다.\r\n④ 데이터에 동일한 수치가 여러 개 있어도 중앙값으로 결측치를 대체할 수 있다.\r\n⑤ 데이터를 수집하는 과정에서 측정 오류가 발생한 값이라도 이상치가 아닐 수 있다.",
  },
  {
    type: "중심 내용 파악",
    policy:
      "비교적 쉬운 난이도로, 세부 내용 파악 유형에서 묻는 범위를 특정한 영역으로 좁힌 것입니다.",
    example:
      "1. ㉠, ㉡에 대한 이해로 가장 적절한 것은?\r\n① ㉠은 소비자 후생 증대 효과가 시장 경쟁 제한의 폐해보다 작은 경우에 허용된다.\r\n② ㉠을 ‘공정거래법’에서 금지하는 목적은 사업자의 가격 결정의 자유를 제한하기 위한 것이다.\r\n③ ㉡을 할 때 사업자는 영업의 자유를 보호받지만 표현의 자유는 보호받지 못한다.\r\n④ ㉡은 사업자가 자사의 홈페이지에 직접 작성해서 게시한 이용 후기를 광고로 활용하는 것을 포함하지 않는다.\r\n⑤ ㉠은 사업자와 소비자 간에, ㉡은 소비자와 소비자 간에 직접 일어나는 행위이다.",
  },
  {
    type: "중심 내용 추론",
    policy: "중심 내용 파악 유형에 비해서는 난도가 높아야 합니다.",
    example:
      "1. ㉠, ㉡에 대한 설명으로 가장 적절한 것은?\r\n① 글 내용이 수행 과제와 관련 있는지 평가하는 것은 ㉠에 해당한다.\r\n② 읽을 글을 선정하기 위해 출판사의 공신력을 따지는 것은 ㉠을 고려한 것이다.\r\n③ ㉡에서는 글이 언제 작성되었는지를 중심으로 판단해야 한다.\r\n④ 정보가 산재해 있는 디지털 환경에서는 ㉠의 필요성이 사라지고 ㉡에 대한 요청이 증가한다.\r\n⑤ 글 내용에 목적에 맞는 정보가 있는지 확인하는 것은 ㉠에, 저자의 경력 정보를 확인하는 것은 ㉡에 관련된다.",
  },
  {
    type: "중심 내용 파악/추론",
    policy:
      "단일한 단어나 구를 지정할 때는 기호를 대신 글상자를 사용하기도 합니다.",
    example:
      "1. 과두제적 경영에 대한 이해로 적절하지 않은 것은?\r\n① 소수의 경영진이 내린 의사 결정이 수직적으로 집행되는 효율성을 추구한다.\r\n② 강한 결속력을 가진 소수의 경영자로 경영진을 이루어 경영권 유지에 강점이 있다.\r\n③ 경영권이 안정되어 중요 기술 개발에 적극적인 투자를 계속하는 데에 유리하다는 장점이 있다.\r\n④ 경영진이 투자자의 유입을 유도하기 위하여 경영 성과를 부풀릴 위험성이 있어 이에 대비할 필요가 있다.\r\n⑤ 경영진과 다수 주주 사이의 이해가 일치하는 경우에는 그렇지 않은 경우보다 기업 가치가 훼손될 위험성이 높아진다.",
  },
  {
    type: "생략된 내용 추론",
    policy:
      "글 전체, 또는 기호로 지정된 영역에 대하여 그 내용이 함축하거나 전제하는 내용을 묻는 방식으로 출제됩니다.",
    example:
      "1. [A]로부터 추론한 내용으로 가장 적절한 것은?\r\n① ‘귤은 맛있다면 귤은 비싸다.’에 포함된 ‘귤은 맛있다.’는 판단적이지 않다.\r\n② ‘표절은 나쁘다.’는 단독으로 진술되었을 때에만 참 또는 거짓일 수 있다.\r\n③ ‘귤은 맛있다.’는 조건문의 일부로 진술될 때는 대상에 속성을 부여하는 내용을 지니지 않는다.\r\n④ 화자는 귤이 맛있음의 속성을 가진다는 내용과 완전히 무관한 채로 ‘귤은 맛있다.’를 진술할 수 있다.\r\n⑤ ‘표절은 나쁘다.’는 화자가 표절에 나쁨을 부여하지 않는 맥락에서도 그것의 판단적 본질을 유지할 수 있다.",
  },
  {
    type: "내용의 인과관계 파악",
    policy:
      "주로 결론에 해당하는 문장을 기호로 지정한 다음, 그러한 결론이 도출된 이유를 묻는 방식으로 출제됩니다.",
    example:
      "1. ㉠의 이유로 가장 적절한 것은?\r\n① 블록체인에 포함된 데이터는 변경이 쉽기 때문이다.\r\n② 블록체인이 여러 노드들에 중복 저장되기 때문이다.\r\n③ 승인 과정에 참여하는 노드 수에 제한이 있기 때문이다.\r\n④ 데이터가 블록체인에 포함되기 위해서는 승인 과정을 필요로 하기 때문이다.\r\n⑤ 동일한 데이터가 블록체인에 연결된 서로 다른 블록에 이중으로 포함되어 있기 때문이다.",
  },
  {
    type: "구체적 사례 적용",
    policy:
      "주로 <보기> 문제 유형으로, 지문에서 설명한 내용과 관계되는 상황을 상정하여 제시합니다.",
    example:
      "1. (가)에 따라 직관ㆍ표상ㆍ사유의 개념을 적용한 것으로 적절하지 않은 것은?\r\n① 먼 타향에서 밤하늘의 별들을 바라보는 것은 직관을 통해, 같은 곳에서 고향의 하늘을 상기하는 것은 표상을 통해 이루어지겠군.\r\n② 타임머신을 타고 미래로 가는 자신의 모습을 상상하는 것과, 그 후 판타지 영화의 장면을 떠올려 보는 것은 모두 표상을 통해 이루어지겠군.\r\n③ 초현실적 세계가 묘사된 그림을 보는 것은 직관을 통해, 그 작품을 상상력 개념에 의거한 이론에 따라 분석하는 것은 사유를 통해 이루어지겠군.\r\n④ 예술의 새로운 개념을 설정하는 것은 사유를 통해, 이를 바탕으로 새로운 감각을 일깨우는 작품의 창작을 기획하는 것은 직관을 통해 이루어지겠군.\r\n⑤ 도덕적 배려의 대상을 생물학적 상이성 개념에 따라 규정하는 것과, 이에 맞서 감수성 소유 여부를 새로운 기준으로 제시하는 것은 모두 사유를 통해 이루어지겠군.",
  },
  {
    type: "글에 드러난 관점 비판",
    policy:
      "일반적으로 <보기>에 지문과 상충하는 견해를 넣고 특정 입장에서 다른 입장을 비판하는 유형입니다.",
    example:
      "1. (나)의 필자의 관점에서 ㉠을 평가한 내용으로 가장 적절한 것은?\r\n① 확장된 인지 과정이 인지 주체의 것일 때에만 성립할 수 있다는 주장은, 지각 이전에 확정된 주체를 전제한 것이므로 타당하지 않다.\r\n② 확장된 인지 과정이 인지 주체의 것일 때에만 성립할 수 있다는 주장은, 의식이 세계를 구성하는 독자적 실체라고 규정하는 것이므로 타당하다.\r\n③ 주체와 통합된 경우에만 확장된 인지 과정이 성립할 수 있다는 주장은, 의식은 물질에 불과하다고 본 것이므로 타당하다.\r\n④ 주체와 통합된 경우에만 확장된 인지 과정이 성립할 수 있다는 주장은, 외부 세계에 대한 지각이 이루어질 수 없다고 보는 것이므로 타당하지 않다.\r\n⑤ 주체와 통합된 경우에만 확장된 인지 과정이 성립할 수 있다는 주장은, 주체와 대상의 분리를 통해서만 지각이 이루어질 수 있다고 보는 것이므로 타당하다.",
  },
  {
    type: "다른 견해와의 비교",
    policy:
      "주로 <보기>에 지문과 다른 견해를 넣고 여러 입장을 비교하는 유형입니다. ",
    example:
      "6. ㉡과 관련하여 ⓐ와 ⓑ의 입장에 대한 반응으로 가장 적절한 것은? [3점]\r\n① 선거 방송 초청 대상 후보자 토론회에서 후보자들이 심층적인 토론을 하지 못한 원인이 시간의 제한이나 참여한 후보자의 수와 관계가 없다면 ⓐ의 입장은 강화되겠군.\r\n② 주요 후보자의 정책이 가진 치명적 허점을 지적하고 좋은 대안을 제시해 유명해진 정치 신인이 선거 방송 초청 대상 후보자 토론회에 초청받지 못한다면 ⓐ의 입장은 약화되겠군.\r\n③ 선거 방송 초청 대상 후보자 토론회에 참여할 적정 토론자의 수를 제한하는 기준이 국민의 합의에 의해 결정되었기 때문에 자의적인 것이 아니라고 한다면 ⓑ의 입장은 강화되겠군.\r\n④ 어떤 후보자가 지지율이 낮은 후보자 간의 별도 토론회에서 뛰어난 정치 역량을 보여 주었음에도 그 토론회에 참여했다는 이유만으로 지지율이 떨어진다면 ⓑ의 입장은 약화되겠군.\r\n⑤ 유권자들이 뛰어난 역량을 가진 소수 정당 후보자를 주요 후보자들과 동시에 비교할 수 있는 가장 효율적인 방법이 선거 방송 초청 대상 후보자 토론회라면 ⓑ의 입장은 약화되겠군.",
  },
  {
    type: "단어의 의미 파악1",
    policy:
      "5개의 단어를 후보로 제시하면서 교체 가능한 단어를 묻는 유형이 있습니다.",
    example:
      "1. 문맥상 ⓐ～ⓔ와 바꿔 쓰기에 적절하지 않은 것은?\r\n① ⓐ : 개선(改善)된\r\n② ⓑ : 파괴(破壞)할\r\n③ ⓒ : 대면(對面)하는\r\n④ ⓓ : 기용(起用)하는\r\n⑤ ⓔ : 해소(解消)한다",
  },
  {
    type: "단어의 의미 파악2",
    policy:
      "1개의 다의어를 지정한 다음 그와 같은 뜻으로 해당 단어가 활용된 예문을 고르는 유형이 있습니다.",
    example:
      "1. ⓐ와 문맥상 의미가 가장 가까운 것은?\r\n① 그는 내 의견에 본인의 견해를 붙여 발언을 이어 갔다.\r\n② 나는 수영에 재미를 붙여 수영장에 다니기로 결정했다.\r\n③ 그는 따뜻한 바닥에 등을 붙여 잠깐 동안 잠을 청했다.\r\n④ 나는 알림판에 게시물을 붙여 동아리 행사를 홍보했다.\r\n⑤ 그는 숯에 불을 붙여 고기를 배부를 만큼 구워 먹었다.",
  },
  // ... 나머지 유형 데이터
];

const typeDataNovel = [
  {
    type: "소설 - 서술상 특징",
    policy:
      "전체 지문을 활용하거나, [A]로 범위를 설정하여 해당 부분에 한정하는 방식 두 종류로 출제됩니다.",
    example:
      "1. [A]의 서술상 특징으로 가장 적절한 것은?\n①중심인물이 알지 못하는 사건을 제시해 긴장감을 조성하고 있다.\n② 공간 이동에 따른 인물의 내면 변화를 회상을 통해 제시하고 있다.\n③ 동시적 사건들의 병치로 사건에 대한 서로 다른 관점을 드러내고 있다.\n④ 한 가지의 목적으로 수렴되는 인물의 의도적인 행위들을 나열하고 있다.\n⑤ 상대를 달리하여 벌이는 인물의 행동을 서술하여 점진적으로 심화되는 갈등을 묘사하고 있다.",
  },
  {
    type: "소설 - 인물의 심리, 태도 이해",
    policy:
      "문제에 직접 인물을 명시할 때에는 1명, 혹은 2명에 대해 묻는 방식으로 출제됩니다.",
    example:
      "1. 장 소저 에 대한 이해로 적절하지 않은 것은? (네모 표기: 장 소저)\n① 부친과 이 시랑이 ‘진진지연’을 맺은 데에는 신기한 꿈이 영향을 미쳤을 것이라고 알고 있다.\n② 이 시랑이 ‘간신의 모해’를 입은 것은 시운이 좋지 않았기 때문이라고 생각했다.\n③ 부친이 ‘세상을 버’린 까닭은 혼약이 어그러진 것과 이 시랑의 죽음에 대한 분노 때문이라고 여겼다.\n④ 왕희가 ‘혼인을 강제하’는 것으로 판단하여 변복 도주했다.\n⑤ ‘성혼 행례’는 하지 않았으나, 승려가 된 양씨를 시어머니로 대했다.",
  },
  {
    type: "소설 - 작품 내용 이해",
    policy: "윗글에 대한 이해로 가장 적절한 것은? 과 같은 형태로 출제됩니다.",
    example:
      "1. 윗글에 대한 이해로 가장 적절한 것은?\n① 이도린은 춘향이 자신에게 호감을 느꼈다는 사실을 알지 못했다.\n② 춘향은 그네를 타기 위해 나들이에 나섰지만 기대했던 바를 달성하지 못했다.\n③ 이도린은 춘향을 부르면 이도린 자신을 만나러 올 것이라는 김한의 말을 믿었다.\n④ 이도린은 월매가 춘향의 어머니라는 사실을 알고 있었지만 이를 모르는 척했다.\n⑤ 옆집 여자 아이는 이도린을 만나기 위해 춘향과 함께 왔지만 풍경을 즐기는 것에 만족했다.",
  },
  {
    type: "소설 - 구절의 의미 이해",
    policy: "ⓐ～ⓔ에 대한 이해로 적절하지 않은 것은? 과 같은 형태로 출제됩니다.",
    example:
      "1. ⓐ～ⓔ에 대한 이해로 적절하지 않은 것은?\n① ⓐ는 권 씨가 사무직 사원들의 대화에 관심이 있었음을 나타내는 반응이다.\n② ⓑ는 장상태가 화를 내며 큰 소리로 명령하였기 때문에 미스 윤이 드러낸 반응이다.\n③ ⓒ는 아내가 집을 나서지 않고 있는 남편 때문에 걱정하여 보인 반응이다.\n④ ⓓ는 전체 사원들이 같은 옷을 입고 군대처럼 도열한 모습을 본 민도식에게 나타난 반응이다.\n⑤ ⓔ는 사원들이 사복을 입은 민도식에 대한 불만을 드러내는 반응이다.",
  },
  {
    type: "소설 - 작품의 맥락 이해",
    policy:
      "기호로 제시한 대상이 작품의 맥락에서 어떠한 의미를 가지는 지를 묻습니다.",
    example:
      "1. ㉮와 ㉯에 대한 이해로 가장 적절한 것은?\n① ㉮는 ㉯에 비해 능동적이므로 인물이 처한 문제 상황에 미치는 영향력이 크다.\n② ㉮는 ㉯와 달리, 시간과 공간에 관여되면서 이야기의 배경에 실감을 더하게 된다.\n③ ㉯는 ㉮와 달리, 희망적인 성격이 강하므로 인물이 원하는 바를 집약한 결과이다.\n④ ㉯에서 연상되는 상황이 현실이 될 경우 ㉮에 투영된 염원은 실현 가능성이 사라진다.\n⑤ ㉮와 ㉯ 모두, 관념적 의미가 부여됨으로써 인물이 이념에 편향되어 있음이 알려진다.",
  },
  {
    type: "소설 - 공간, 시간, 소재의 기능 파악",
    policy:
      "하나의 대상에 관해 묻는 경우, \\로 인용하거나 네모 등의 표시를 통해 소재를 제시합니다.",
    example:
      "1. 윗글의 ‘오동나무’에 대한 이해로 가장 적절한 것은?\n① ‘나’가 계절의 자연스러운 변화와 세월의 흐름을 느끼게 되는 경험적 대상이다.\n② 가난한 마을이지만 사람들로 하여금 호사를 누릴 수 있게 하는 경제적 기반이다.\n③ ‘어머니’가 결혼 후에 심고 정성을 다해 키워 내어 무성해진 애착의 결실이다.\n④ 동네 사람들이 마을의 특징에 부합한 별명을 자기 마을에 붙일 때 적용한 단서이다.\n⑤ ‘아버지’가 자식을 얻은 기쁨을 이웃과 나눌 생각에 마을 곳곳에 심은 상징적 기념물이다.",
  },
  {
    type: "소설 - 발화의 내용 및 기능",
    policy:
      "기호 혹은, 범위로 대화 부분을 설정하여 해당 부분에 관해 묻는 방식으로 출제됩니다.",
    example:
      "1. [A]～[C]에 대한 설명으로 적절하지 않은 것은?\n① [A]에서 인물은 상대의 행위가 옳지 않다고 판단하여, 반복적으로 추궁하며 상대가 잘못했음을 분명히 한다.\n② [B]에서 인물은 상대의 주장이 사실과 다르다며, 모르고 그랬다는 말을 반복함으로써 자신의 억울함을 알린다.\n③ [C]에서 인물은 추측을 바탕으로 상대의 발언이 신뢰하기 어렵다고 반박하고, 상대의 반응에 아랑곳하지 않고 거짓으로 답했다며 몰아붙인다.\n④ [A]에서 인물은 상대의 행위와 동기를 함께 비난하고, [B]에서 인물은 상대의 비난을 파악하지 못해 자신의 행위에 대해서만 인정한다.\n⑤ [A]에서 인물이 상대에게 화를 내자, [B]에서 인물은 당황하며 자신을 방어하지만, [C]에서 갈등 상황은 지속된다.\n\n1. ⓐ～ⓔ를 이해한 내용으로 적절하지 않은 것은?\n① ⓐ:편지의 수신인이 누구인지 말해 주며 상대가 편지의 중요성을 인식하게 하고 있다.\n② ⓑ:손주들을 호명하며 격해진 감정과 그들을 불쌍해하는 마음을 표출하고 있다.\n③ ⓒ:자신의 운명은 하늘의 뜻이라고 함으로써 집에 온 자신을 책망하지 말 것을 부탁하고 있다.\n④ ⓓ:옥황상제의 부름을 거절할 수 없다고 말함으로써 이별이 예정되어 있음을 언급하고 있다.\n⑤ ⓔ:백학선과 약주를 선물함으로써 상대를 걱정하는 마음을 드러내고 있다.",
  },
  {
    type: "소설 - <보기> 문항",
    policy:
      "<보기>를 참고하여 윗글을 감상한 내용으로 적절하지 않은 것은?과 같은 형태로 출제됩니다.",
    example:
      "1. <보기>를 참고하여 윗글을 감상한 내용으로 적절하지 않은 것은?\n<보 기>\n  ｢무성격자｣의 정일은 자신을 구속하는 속물적 욕망을 경멸하고 현실에서의 적극적인 행동을 주저하는 한편, 자신과 주변에 관심을 집중한다. 그는 주변 대상을 관찰하여 그 의미를 파악하고, 파악한 내용에 반응하며, 그런 자신을 분석하기도 한다. 나아가 관찰과 분석을 수행하는 자신의 내면마저 대상화함으로써 인간 심리의 중층적 구조를 드러낸다.\n\n① 산판알을 놓으며 이익을 따지는 상대를 경멸하면서도 산판알이 올라가는 것을 주목하는 데에서, 자신을 구속하는 속물적 욕망으로부터 자유롭지 못한 모습을 찾을 수 있군.\n② 상대의 웃음에서 공모 의사를 읽어 내자 얼굴에 흐르는 미끄러지는 듯한 웃음을 깨닫는 데에서, 상대에 대한 불쾌감을 웃음으로 무마하려는 자신을 의식하는 모습을 찾을 수 있군.\n③ 중문 안으로 들어가는 상대를 불러내지는 못하고 자신이 그를 부르지 못한 이유를 생각하는 데에서, 행동을 주저하고 자신에게로 관심을 돌리는 모습을 찾을 수 있군.\n④ 상대의 고통을 바라보며 의지력을 우러러보는 듯한 마음이 있는 자신을 발견하는 데에서, 상대와의 차이를 인식하는 스스로의 내면마저 대상화하는 모습을 찾을 수 있군.\n⑤ 물줄기를 바라보는 상대로부터 이전에는 한 번도 보지 못한 눈을 확인하는 데에서, 주변 대상을 관찰하여 상대가 내비치는 생에 대한 강렬한 동경을 파악하는 모습을 찾을 수 있군.",
  },
]
const typeDataPoem = [

  {
    type: "운문 - 표현상 특징",
    policy:
      "(가)에 대한 선지 1개, (나)에 대한 선지 1개, (가)(나) 모두와 관련된 선지 3개로 구성됩니다.",
    example:
      "1. (가), (나)에 대한 설명으로 가장 적절한 것은?\n① (가)는 과거를 회상하며 현실을 관망하는 태도를 드러내고 있다.\n② (나)는 상징성을 띤 사건의 전개를 통해 주제를 암시하고 있다.\n③ (가)와 (나)는 모두 음성 상징어를 활용하여 상상 세계의 경이로움을 나타내고 있다.\n④ (가)와 (나)는 모두 동일한 시구의 반복과 변주를 통해 시적 분위기를 고조하고 있다.\n⑤ (가)는 위로하는 어조로, (나)는 충고하는 어조로 시적 청자에게 말을 건네고 있다.",
  },
  {
    type: "운문 - 시어, 시구의 의미와 기능 파악",
    policy:
      "㉠～㉤을 이해한 내용으로 적절하지 않은 것은? 과 같은 형태로 출제됩니다.",
    example:
      "1. ㉠～㉤에 대한 이해로 적절하지 않은 것은?\n① ㉠은 대상이 이전에는 제대로 파악되지 않았음을 드러내는 표현이다.\n② ㉡은 ‘저녁 연기’의 형상으로 ‘한 가정’의 상황과 처지를 시각화한 표현이다.\n③ ㉢은 ‘맨살’을 드러낸 ‘돌들’이 부대끼는 형상으로 세파에 시달리는 모습을 나타내는 표현이다.\n④ ㉣은 ‘차를 마시는 것’이 화자의 선호에 따른 주체적 행위임을 드러내는 표현이다.\n⑤ ㉤은 ‘나’에 대한 현재의 인식이 이전과는 달라졌음을 드러내는 표현이다.",
  },
  {
    type: "운문 - 시구의 비교와 대조",
    policy: "비교, 대조되는 두 대상은 유사한 형태로 설정되어야 합니다.",
    example:
      "1. ㉠, ㉡에 대한 이해로 가장 적절한 것은? (㉠: 꿈같이 만나 있어, ㉡: 꿈에나 보려 하니)\n① ㉠은 흐릿한 기억 때문에 혼란스러운 화자의 심정을 나타낸다.\n② ㉡은 현실에서는 화자가 문제를 해결할 수 없어서 선택한 방법이다.\n③ ㉠은 임과의 만남에 대한 기대에서, ㉡은 임과의 이별에 대한 망각에서 비롯된다.\n④ ㉠은 이미 일어난 일에 대해 회상하고, ㉡은 곧 일어날 일에 대해 단정하고 있다.\n⑤ ㉠은 인연의 우연성에 대한, ㉡은 재회의 필연성에 대한 화자의 우려를 드러내고 있다.",
  },
  {
    type: "운문 - 공간, 시간, 소재의 기능 파악",
    policy:
      "일반적으로 2개의 대상을 제시하며, 이때 두 대상이 유사한 종류여야 합니다.",
    example:
      "1. ㉠과 ㉡을 비교한 내용으로 가장 적절한 것은?\n① ㉠은 ‘향’에게 귀환이 금지된 공간이고, ㉡은 ‘아이들’에게 이탈이 금지된 공간이다.\n② ㉠은 ‘향’이 자기반성을 수행하는 공간이고, ㉡은 ‘아이들’이 ‘그’의 요청을 수행하는 공간이다.\n③ ㉠은 ‘향’이 본성을 찾아가는 낯선 공간이고, ㉡은 ‘아이들’이 개성을 박탈당한 상실의 공간이다.\n④ ㉠은 ‘향’의 노동과 놀이가 공존하던 공간이고, ㉡은 ‘아이들’의 놀이가 사라지고 노동만 남은 공간이다.\n⑤ ㉠은 ‘향’과 화자의 우호적 관계가 드러나는 공간이고, ㉡은 ‘아이들’과 ‘그’의 상생 관계가 드러나는 공간이다.",
  },
  {
    type: "운문 - 작품의 맥락 이해",
    policy: "여러 범위를 설정하여 각 부분의 내용을 비교합니다.",
    example:
      "1. [A]～[F]에 대한 이해로 가장 적절한 것은?\n① [A]에서 참나무가 벌목으로 썩어 가는 모습은, [B]에서 바람에 흔들리는 나무의 모습과 순환적 관계를 형성한다.\n② [B]에서 참나무의 상태에 변화를 가져온 움직임은, [C]에서 버섯이 피어나는 상황과 순차적 관계를 형성한다.\n③ [C]에서 참나무의 상처에 생명이 생성되는 순간은, [D]에서 나무의 고통이 멈추는 과정과 대립적 관계를 형성한다.\n④ [D]에서 참나무의 모습에 일어난 변화는, [E]에서 낙엽이나 바람이 처한 상황과 인과적 관계를 형성한다.\n⑤ [E]에서 참나무의 주변에 존재하는 사물들은, [F]에서 나무를 채워 주는 존재로 제시된 대상과 동질적 관계를 형성한다.",
  },
  {
    type: "운문 - <보기> 문항",
    policy:
      "<보기>를 참고하여 (가), (나)를 감상한 내용으로 적절하지 않은 것은?과 같은 형태로 출제됩니다.",
    example:
      "1. <보기>를 참고하여 (가), (나)를 감상한 내용으로 적절하지 않은 것은?\n<보 기>\n  (가)와 (나)는 모두 부정적 현실을 비판한 작품이다. (가)는 물질문명의 허위와 병폐에 물들어 가는 공동체가 농경 문화의 전통에 바탕을 두고 건강한 생명력과 순수성을 회복하기를 소망하는 작가 의식을 담고 있다. (나)는 환영(幻影)을 통해 대중의 이성을 마비시키고 대중을 획일적으로 길들이는 권력의 기만적 통치술에 대한 비판 의식을 담고 있다.\n\n① (가)에서 ‘차라리 그 미개지에로 가자’라는 화자의 권유는 공동체의 터전을 확장하여 순수성을 지켜 나가려는 의식을 보여 주는군.\n② (나)에서 골목이 ‘가장 햇빛이 안 드는 곳’으로 판명되었다는 것은 ‘유리 담장’이 대중을 기만하는 환영의 장치였음을 보여 주는군.\n③ (가)에서 ‘기생충의 생리’는 자족적인 농경 문화 전통에 반하는 문명의 병폐를, (나)에서 ‘주장하는 아이’의 추방은 획일적으로 통제된 사회의 모습을 보여 주는군.\n④ (가)에서 ‘발돋움의 흉내’를 낸다는 것은 물질문명에 물들어 가는 상황을, (나)에서 ‘곧 즐거워했다’는 것은 권력의 술수에 대중이 길들여지고 있는 상황을 보여 주는군.\n⑤ (가)에서 ‘떼지어 춤추던’ 모습은 농경 문화 공동체의 건강한 생명력을, (나)에서 ‘일렬로’, ‘묵묵히’ 벽돌을 나르는 모습은 권력에 종속된 대중의 형상을 보여 주는군.",
  }, // ... 나머지 유형 데이터
  {
    type: "단어의 의미 파악1",
    policy:
      "5개의 단어를 후보로 제시하면서 교체 가능한 단어를 묻는 유형이 있습니다.",
    example:
      "1. 문맥상 ⓐ～ⓔ와 바꿔 쓰기에 적절하지 않은 것은?\r\n① ⓐ : 개선(改善)된\r\n② ⓑ : 파괴(破壞)할\r\n③ ⓒ : 대면(對面)하는\r\n④ ⓓ : 기용(起用)하는\r\n⑤ ⓔ : 해소(解消)한다",
  },
  {
    type: "단어의 의미 파악2",
    policy:
      "1개의 다의어를 지정한 다음 그와 같은 뜻으로 해당 단어가 활용된 예문을 고르는 유형이 있습니다.",
    example:
      "1. ⓐ와 문맥상 의미가 가장 가까운 것은?\r\n① 그는 내 의견에 본인의 견해를 붙여 발언을 이어 갔다.\r\n② 나는 수영에 재미를 붙여 수영장에 다니기로 결정했다.\r\n③ 그는 따뜻한 바닥에 등을 붙여 잠깐 동안 잠을 청했다.\r\n④ 나는 알림판에 게시물을 붙여 동아리 행사를 홍보했다.\r\n⑤ 그는 숯에 불을 붙여 고기를 배부를 만큼 구워 먹었다.",
  },
];

// RadioCard 컴포넌트 정의
function RadioCard(props) {
  const { getInputProps, getCheckboxProps } = useRadio(props);
  const { colorScheme } = props;
  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label" mb={2}>
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="full"
        boxShadow="md"
        _checked={{
          bg: `${colorScheme}.600`,
          color: "white",
          borderColor: `${colorScheme}.600`,
        }}
        _focus={{
          boxShadow: "outline",
        }}
        px={3}
        py={2}
        bg={`${colorScheme}.100`}
      >
        {props.children}
      </Box>
    </Box>
  );
}

export default function CreateQuestionDashboard() {
  const [isLoading, setLoading] = useState(false);
  const [cards, setCards] = useState([]);
  const [selectedType, setSelectedType] = useState("");
  const [generatedQuestions, setGeneratedQuestions] = useState([]);
  const [generatedSolutions, setGeneratedSolutions] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [includeSupplement, setIncludeSupplement] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();
  const [checkedBoxList, setCheckedBoxList] = useState([]);
  const [keywords, setKeywords] = useState([]); // 저장된 키워드
  const [keywordInput, setKeywordInput] = useState(""); // 키워드 입력 값
  const token = localStorage.getItem("token");
  const [paragraphs, setParagraphs] = useState([]);
  const [paragraphKeywords, setParagraphKeywords] = useState([]);
  const [paragraphKeywordInputs, setParagraphKeywordInputs] = useState([]);
  const [paragraphKeywordTypes, setParagraphKeywordTypes] = useState([]);

  // Modal 상태와 관련된 state
  const [typeDetails, setTypeDetails] = useState(null);

  // 카테고리에 따라 유형 데이터 선택
  const category = location.state.category || "";
  const isMoonhak = category.includes("문학 작품");
  let currentTypeData = ""; // 'let'으로 변경
  if (isMoonhak) {
      if (category === "문학 작품 - 현대 소설") {
          currentTypeData = typeDataNovel;
      } else if (category === "문학 작품 - 현대 시") {
          currentTypeData = typeDataPoem;
      }
  } else {
      currentTypeData = typeData;
  }

  


  // 문제 편집 상태 관리 변수
  const [editMode, setEditMode] = useState(Array(1).fill(false));

  // 사용자 입력 추적 위한 temp 변수
  const [tempInput, setTempInput] = useState("");

  const [rating, setRating] = useState(null); // 별점 상태
  const [comment, setComment] = useState(""); // 의견 상태
  const [sendFeedback, setSendFeedback] = useState(false); // Whether to send the rating and comment

  const [isShowPartSelectButton, setIsShowPartSelectButton] = useState(false);
  const [isSelectingPartOfPassage, SetIsSelectingPartOfPassage] = useState(
    false
  );
  const [existingQuestions, setExistingQuestions] = useState([]);  // 조회된 기존 문제들
  const [selectedTexts, setSelectedTexts] = useState([]);
  const [highlighterColor, SetHilighterColor] = useState("red");
  const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });
  const [showPopup, setShowPopup] = useState(false);
  const [selectionOffsets, setSelectionOffsets] = useState({});
  const [highlightRanges, setHighlightRanges] = useState([]);
  const passageId = location.state?.passage_id;


  useEffect(() => {
    const initialParagraphs = extractTextFromJson(
      location.state.contents
    ).split("\n\n");
    setParagraphs(initialParagraphs);
  }, []);

  useEffect(() => {
    console.log(selectedTexts);
  }, [selectedTexts]);

  useEffect(() => {
    // 페이지 로드 시 해당 Passage의 기존 문제들을 조회
    const fetchExistingQuestions = async () => {
      setLoading(true);
      try {
        const response = await apiClient.get(`/problem_generator/passage_questions/${passageId}/`);
        setExistingQuestions(response.data.questions);
      } catch (error) {
        console.error("Error fetching existing questions:", error);
        toast({
          title: "문제 조회 실패",
          description: "문제를 조회하는 도중 오류가 발생했습니다.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchExistingQuestions();
  }, [passageId, toast]);

  const addCard = () => {
    setIsModalOpen(true);
  };

  const deleteCard = (id) => {
    setCards((prevCards) => prevCards.filter((card) => card.id !== id));
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedType("");
    setTypeDetails(null);
    setIncludeSupplement(false); // 모달 창 닫을 때 보기 포함 여부 초기화
    setIsShowPartSelectButton(false);
  };

  const handleTypeSelect = (value) => {
    setSelectedType(value);
    const selectedDetail = currentTypeData.find((item) => item.type === value);
    setTypeDetails(selectedDetail);
    setIsShowPartSelectButton(true);
  };

  const handleCheckboxChange = (e) => {
    setIncludeSupplement(e.target.checked);
  };

  const handleSaveClick = async () => {
    setIsEditing(false);

    // 전체 지문을 저장하기 위해 paragraphs를 하나의 텍스트로 합칩니다.
    const fullText = paragraphs.join("\n\n");

    try {
      await apiClient.put(
        `/problem_generator/passages/${location.state.passage_id}`,
        {
          contents: fullText,
        }
      );
      toast({
        title: "지문 저장 성공",
        description: "전체 지문이 성공적으로 저장되었습니다.",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    } catch (error) {
      console.error("Error updating passage", error);
      toast({
        title: "지문 저장 실패",
        description: "지문 저장 중 오류가 발생했습니다.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    }
  };

  const extractTextFromJson = (jsonString) => {
    try {
      // ```json 및 ``` 구문 제거
      const cleanedJsonString = jsonString
        .replace(/^```json/, "")
        .replace(/```$/, "")
        .trim();

      // 문자열을 JSON 객체로 변환
      const jsonObject = JSON.parse(cleanedJsonString);
      return extractTextRecursively(jsonObject);
    } catch (error) {
      console.warn("Invalid JSON string:", error);
      return jsonString; // JSON 변환 실패 시 원본 문자열 반환
    }
  };

  // JSON 객체에서 재귀적으로 텍스트만 추출하는 함수
  const extractTextRecursively = (json) => {
    let text = "";
    if (typeof json === "string") {
      return json;
    } else if (typeof json === "object" && json !== null) {
      for (const key in json) {
        text += extractTextRecursively(json[key]) + "\n\n";
      }
    }
    return text.trim();
  };

  /**
   * 선택된 텍스트의 시작점과 끝점을 전체 텍스트 기준으로 가져오는 함수
   * @param {Selection} selection
   * @returns
   */
  const getSelectionOffsets = (selection) => {
    // 전체 텍스트 가져오기
    const anchorNode = selection.anchorNode;
    let parentNode = anchorNode.parentNode;
    if (parentNode.className === "highlighted") {
      //한번 더
      parentNode = parentNode.parentNode;
    }
    const innerText = parentNode.innerText;
    const childNodes = parentNode.childNodes;

    // 선택한 텍스트의 시작지점 찾기. 전체 텍스트 기준.
    const anchorNodeOffset = innerText.indexOf(anchorNode.data);
    let startOffsetFromStart = anchorNodeOffset + selection.anchorOffset;

    // 선택한 텍스트의 끝지점 찾기. 전체 텍스트 기준.
    const focusNode = selection.focusNode;
    const focusNodeOffset = innerText.indexOf(focusNode.data);
    let endOffsetFromStart = focusNodeOffset + selection.focusOffset;

    // 만약 선택한 텍스트가 역순으로 선택되었다면, 시작점과 끝점을 바꿔줍니다.
    if (startOffsetFromStart > endOffsetFromStart) {
      const temp = startOffsetFromStart;
      startOffsetFromStart = endOffsetFromStart;
      endOffsetFromStart = temp;
    }
    return { startOffset: startOffsetFromStart, endOffset: endOffsetFromStart };
  };

  const handleMouseUp = (event) => {
    setTimeout(() => {
      // '지문 중 일부 선택' 상태가 아니라면 종료.
      if (!isSelectingPartOfPassage) {
        return;
      }
      // ↓ Status: (지문 중 일부 선택 상태)

      const selection = window.getSelection();
      const selectedText = selection.toString();

      // 선택한 텍스트가 2글자 이상이 아니라면 팝업을 숨기기.
      if (selectedText.length < 2) {
        setShowPopup(false);
        return;
      }
      // ↓ Status: (지문 중 일부 선택) && (선택한 텍스트가 2글자 이상)

      //#region Validation Check
      // whether highlighter is possible.

      // 선택한 텍스트의 전체 텍스트 기준 시작 위치, 끝 위치 가져오기
      const { startOffset, endOffset } = getSelectionOffsets(selection);
      // 선택한 range가 중복으로 되진 않는지 확인
      const isOverlap = highlightRanges.some(
        (range) =>
          // 1. endOffset이 range 범위 안에 들어가면 중복.
          // 2. startOffset이 range 범위 안에 들어가면 중복.
          // 3. 드래그 한 부분이 range 범위를 포함하는지?
          (endOffset > range.start && endOffset < range.end) ||
          (startOffset > range.start && startOffset < range.end) ||
          (startOffset <= range.start && endOffset >= range.end)
      );

      // 중복되면 끝내기
      if (isOverlap) {
        toast({
          title: "중복된 범위",
          description: "이미 선택된 범위와 중복되는 부분이 있습니다.",
          status: "warning",
          duration: 1000,
          isClosable: true,
        });
        return;
      }
      //#endregion
      // ↓ Status: (범위 중복 없음)

      // 선택한 텍스트의 offset 들 저장
      setSelectionOffsets({ startOffset: startOffset, endOffset: endOffset });
      // 선택한 텍스트의 중간 위치값 계산
      const range = selection.getRangeAt(0);
      const selectedTextRect = range.getBoundingClientRect();
      // baseNode의 getBoundingClientRect 호출 (기준위치)
      const baseNode = range.startContainer.parentNode;
      const baseNodeRect = baseNode.getBoundingClientRect();
      const selectedTextMiddle =
        selectedTextRect.left - baseNodeRect.left + selectedTextRect.width / 2;
      const selectedTextTop =
        selectedTextRect.top + window.scrollY + selectedTextRect.height / 2;

      // 팝업 위치 설정 및 표시
      setPopupPosition({ x: selectedTextMiddle, y: selectedTextTop });
      setShowPopup(true);
    }, 0);
  };

  /**
   * 선택된 텍스트를 특정 색으로 감싸는 함수
   * @param {string} selectedText - 선택된 텍스트
   * @param {string} bgcolor - 배경색
   * @returns {HTMLSpanElement} - 색상이 적용된 SPAN 요소
   */
  const applyHighlight = (selectedText, bgcolor) => {
    const span = document.createElement("span");
    span.classList.add("highlighted"); // .highlighted 클래스를 추가하여 나중에 쉽게 구별
    span.style.backgroundColor = bgcolor; // 배경색 지정
    span.textContent = selectedText;
    return span;
  };

  /**
   * 하이라이트 버튼 클릭 시 실행되는 함수
   * 1. 하이라이트 시각화
   * 2. 시작점과 끝점 위치를 저장
   * 3. 드래그 텍스트 저장
   * 4. 팝업 닫기
   * @returns
   */
  const handleHighlightBtnClick = () => {
    // 1. 하이라이트 시각화
    // 선택된 텍스트의 범위를 가져옵니다.
    const selection = window.getSelection();
    let range = selection.getRangeAt(0);
    // 선택된 텍스트를 삭제하고 새로운 SPAN 요소 삽입
    const selectedText = range.toString();
    const newSpan = applyHighlight(
      selectedText,
      pastelColors[highlighterColor]
    );
    range.deleteContents();
    range.insertNode(newSpan);

    // 선택된 텍스트의 시작점과 끝점을 전체 텍스트 기준으로 가져오기
    const { startOffset, endOffset } = selectionOffsets;

    // 2. 시작점과 끝점 위치를 저장
    // higlightRanges에 새로운 범위 추가
    setHighlightRanges([
      ...highlightRanges,
      { start: startOffset, end: endOffset },
    ]);

    // 3. 문구가 뭔지 저장
    // 선택한 텍스트들에 추가
    setSelectedTexts([...selectedTexts, selectedText]);

    // 4. 팝업 닫기
    // 하이라이트 버튼 닫기, 선택된 텍스트 범위를 모두 해제
    setShowPopup(false);
    document.getSelection().removeAllRanges();

    return;
  };

  /**
   * 전체 글자 수 계산 함수
   */
  const getTotalCharacterCount = () => {
    return paragraphs.reduce((total, paragraph) => total + paragraph.length, 0);
  };

  const handleSelectPartOfPassage = (selectedType) => {
    SetIsSelectingPartOfPassage(true);
    SetHilighterColor(typeColors[selectedType]);
  };

  const addQuestion = () => {
    if (typeDetails) {
      setCards((prevCards) => [
        ...prevCards,
        {
          id: prevCards.length,
          type: selectedType,
          policy: typeDetails.policy,
          example: typeDetails.example,
          includeSupplement,
          selectedTexts: selectedTexts,
        },
      ]);
      setSelectedType("");
      setSelectedTexts([]);
      setTypeDetails(null);
      setIncludeSupplement(false); // 문제 추가 시 보기 포함 여부 초기화
      closeModal();
    } else {
      toast({
        title: "모든 필드를 입력해 주세요.",
        description: "문제 유형과 문제 본문을 입력해 주세요.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleParagraphKeywordInput = (index, value) => {
    const newInputs = [...paragraphKeywordInputs];
    newInputs[index] = value;
    setParagraphKeywordInputs(newInputs);
  };

  const handleParagraphKeywordTypeChange = (index, value) => {
    const newTypes = [...paragraphKeywordTypes];
    newTypes[index] = value;
    setParagraphKeywordTypes(newTypes);
  };

  const handleParagraphChange = (index, newValue) => {
    const newParagraphs = [...paragraphs];
    newParagraphs[index] = newValue;
    setParagraphs(newParagraphs);
  };

  const handleAddParagraphKeyword = (index) => {
    const keyword = paragraphKeywordInputs[index].trim();
    const keywordType = paragraphKeywordTypes[index] || "정의"; // 기본값을 "정의"로 설정
    if (keyword === "") return;

    const newParagraphKeywords = [...paragraphKeywords];
    if (!newParagraphKeywords[index]) {
      newParagraphKeywords[index] = [];
    }

    // 키워드와 키워드 타입을 함께 저장
    newParagraphKeywords[index].push({ keyword, type: keywordType });
    setParagraphKeywords(newParagraphKeywords);

    // 입력 필드와 타입 초기화
    const newInputs = [...paragraphKeywordInputs];
    newInputs[index] = "";
    setParagraphKeywordInputs(newInputs);

    const newTypes = [...paragraphKeywordTypes];
    newTypes[index] = "정의";
    setParagraphKeywordTypes(newTypes);
  };

  const handleRemoveParagraphKeyword = (paragraphIndex, keyword) => {
    const newParagraphKeywords = [...paragraphKeywords];

    // 해당 문단에서 특정 키워드 객체를 제거
    newParagraphKeywords[paragraphIndex] = newParagraphKeywords[
      paragraphIndex
    ].filter(
      (item) => item.keyword !== keyword // 객체의 keyword 속성을 비교
    );

    setParagraphKeywords(newParagraphKeywords);
  };

  const handleRegenerateParagraph = (index) => {
    setLoading(true);
    apiClient
      .post(
        `/problem_generator/regenerate_paragraph/`,
        {
          paragraph: paragraphs[index],
          keywords: paragraphKeywords[index],
        }
      )
      .then((response) => {
        const newParagraphs = [...paragraphs];
        newParagraphs[index] = response.data.new_paragraph;
        setParagraphs(newParagraphs);
      })
      .catch((error) => {
        toast({
          title: "문단 재생성 실패",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        console.error("Error regenerating paragraph:", error);
      })
      .finally(() => setLoading(false));
  };

  const createQuestion = () => {
    const selectedOptions = cards.map((card) => ({
      type: card.type,
      includeSupplement: card.includeSupplement, // 보기 포함 여부 추가
      example: card.example,
      policy: card.policy,
      selectedTexts: card.selectedTexts,
    }));
    const data = {
      passage_id: location.state.passage_id,
      passage_title: location.state.title,
      contents: location.state.contents,
      category: location.state.category,
      selected_options: selectedOptions,
    };
    setLoading(true);
    apiClient
      .post(`/problem_generator/generate/`, data)
      .then((response) => {
        setGeneratedSolutions(response.data.solutions);
        setGeneratedQuestions(response.data.questions);
        // 편집여부 상태변수 업데이트
        setEditMode(Array(response.data.questions.length).fill(false));
        toast({
          title: "문제가 성공적으로 생성되었습니다.",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
      })
      .catch((err) => {
        console.error(err);
        toast({
          title: "문제 생성에 실패했습니다.",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleGeneratedCheckboxChange = (e, index) => {
    if (e.target.checked) {
      setCheckedBoxList((prevList) => [...prevList, index]);
    } else {
      setCheckedBoxList((prevList) =>
        prevList.filter((item) => item !== index)
      );
    }
  };

  const saveSelectedQuestions = () => {
    if (editMode.includes(true)) {
      toast({
        title: "편집 중인 문제가 있습니다.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const selectedQuestions = checkedBoxList.map(
      (index) => generatedQuestions[index]
    );
    const selectedSolutions = checkedBoxList.map(
      (index) => generatedSolutions[index]
    );
    const data = {
      questions: selectedQuestions,
      solutions: selectedSolutions,
      passage_id: location.state.passage_id,
    };
    if (sendFeedback) {
      if (rating >= 0 && rating != null) {
        data.rating = rating;
      }

      if (comment.trim()) {
        data.feedback = comment; // Add feedback only if comment has content
      }
    }

    setLoading(true);

    apiClient
      .post(`/problem_generator/save_selected_questions/`, data)
      .then((response) => {
        toast({
          title: "문제가 성공적으로 저장되었습니다.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        // navigate("/problem-bank");
      })
      .catch((err) => {
        console.error(err);
        toast({
          title: "문제 저장에 실패했습니다.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // useRadioGroup 훅 사용
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "questionType",
    value: selectedType,
    onChange: handleTypeSelect,
  });
  const group = getRootProps();

  // 편집 상태 변경 함수
  const enterEditMode = (index) => {
    const newEditMode = [...editMode];
    newEditMode[index] = true;
    setEditMode(newEditMode);
  };

  const exitEditMode = (index) => {
    const newEditMode = [...editMode];
    newEditMode[index] = false;
    setEditMode(newEditMode);
  };

  const updateQuestionProperty = (index, propertyName, value) => {
    let updatedQuestions = [...generatedQuestions];
    updatedQuestions[index][propertyName] = value;
    setGeneratedQuestions(updatedQuestions);
  };

  const updateSolutionPropery = (index, propertyName, value) => {
    let updatedSolutions = [...generatedSolutions];
    updatedSolutions[index][propertyName] = value;
    setGeneratedSolutions(updatedSolutions);
  };

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <Grid templateColumns={{ md: "1fr", lg: "1fr" }} my="26px" gap="24px">
        <Box bg="white" borderWidth="1px" borderRadius="lg" p={3}>
          <Box p={3}>
            <Center mb={3}>
              <Heading size="md">{location.state.title}</Heading>
            </Center>
            <Flex justifyContent="space-between" alignItems="center">
              <Tag>{location.state.category}</Tag>
              {isEditing ? (
                <Button
                  borderRadius="full"
                  colorScheme="teal"
                  onClick={handleSaveClick}
                >
                  전체 지문 저장
                </Button>
              ) : (
                <Button
                  borderRadius="full"
                  onClick={() => setIsEditing(!isEditing)}
                >
                  수정
                </Button>
              )}
            </Flex>
          </Box>
          <Box mb={4} p={4} bg="gray.50" borderRadius="lg" textAlign="center">
            <Text fontSize="sm">전체 글자 수</Text>
            <Text fontSize="2xl" fontWeight="bold" color="teal.600">
              {getTotalCharacterCount()}자
            </Text>
          </Box>
          {isEditing === false ? (
            <Text whiteSpace="pre-wrap" onClick={handleMouseUp}>
              {paragraphs.join("\n\n\n")}
              {showPopup && (
                <Box
                  position="absolute"
                  top={`${popupPosition.y}px`}
                  left={`${popupPosition.x}px`}
                  zIndex={10}
                >
                  <Button
                    size="sm"
                    colorScheme={highlighterColor}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleHighlightBtnClick("Option 1");
                    }}
                  >
                    하이라이트
                  </Button>
                </Box>
              )}
            </Text>
          ) : (
            paragraphs.map((paragraph, index) => (
              <Box key={index} mb={4} p={4} borderWidth="1px" borderRadius="lg">
                <Text mb={2} fontWeight="bold">
                  문단 {index + 1}
                </Text>
                <Textarea
                  value={paragraph}
                  onChange={(e) => handleParagraphChange(index, e.target.value)}
                  mb={3}
                  minHeight="100px"
                />

                <Flex mb={2}>
                  <Input
                    placeholder="문단 키워드 입력"
                    borderRadius="full"
                    value={paragraphKeywordInputs[index]}
                    onChange={(e) =>
                      handleParagraphKeywordInput(index, e.target.value)
                    }
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault(); // 기본 Enter 동작을 막기
                        handleAddParagraphKeyword(index);
                        handleParagraphKeywordInput(index, ""); // 입력 필드를 초기화
                      }
                    }}
                    mr={2}
                  />
                  <Select
                    placeholder="키워드 타입 선택"
                    value={paragraphKeywordTypes[index] || "정의"}
                    onChange={(e) =>
                      handleParagraphKeywordTypeChange(index, e.target.value)
                    }
                    width="30%"
                    mr={2}
                    borderRadius="full"
                  >
                    <option value="정의">정의</option>
                    <option value="예시">예시</option>
                    <option value="예시">상세 설명</option>

                  </Select>
                  <Button
                    colorScheme="teal"
                    onClick={() => handleAddParagraphKeyword(index)}
                    borderRadius="full"
                  >
                    추가
                  </Button>
                </Flex>

                <Wrap mb={3}>
                  {paragraphKeywords[index] &&
                    paragraphKeywords[index].map((item, kidx) => (
                      <WrapItem key={kidx}>
                        <Tag colorScheme="teal" borderRadius="full">
                          <TagLabel>
                            {item.keyword} ({item.type})
                          </TagLabel>
                          <TagCloseButton
                            onClick={() =>
                              handleRemoveParagraphKeyword(index, item.keyword)
                            }
                          />
                        </Tag>
                      </WrapItem>
                    ))}
                </Wrap>
                <Button
                  colorScheme="teal"
                  onClick={() => handleRegenerateParagraph(index)}
                  isLoading={isLoading}
                  borderRadius="full"
                >
                  문단 재생성
                </Button>
              </Box>
            ))
          )}
        </Box>
        <Box bg="white" borderWidth="1px" borderRadius="lg" p={3}>
          <Box p={3}>
            <Center>
              <Heading size="md">출제된 문제 목록</Heading>
            </Center>
          </Box>

          {/* 조회된 기존 문제들을 보여주는 컴포넌트 */}
          {existingQuestions.length > 0 ? (
            existingQuestions.map((question, index) => (
              <Box
                key={index}
                bg="gray.50"
                borderWidth="1px"
                borderRadius="lg"
                p={3}
                mb={4}
              >
                <Heading size="sm">
                  문제 {index + 1}: {question.question}
                </Heading>
                {question.supplement ? (
                  <Box
                    bg="white"
                    borderWidth="1px"
                    borderRadius="lg"
                    p={3}
                    whiteSpace="pre-wrap"
                    mt={3}
                  >
                    {question.supplement}
                  </Box>
                ) : null}
                <VStack spacing={2} mt={2} alignItems="start">
                  <Text>1. {question.choice_1}</Text>
                  <Text>2. {question.choice_2}</Text>
                  <Text>3. {question.choice_3}</Text>
                  <Text>4. {question.choice_4}</Text>
                  <Text>5. {question.choice_5}</Text>
                </VStack>
              </Box>
            ))
          ) : (
            <Text>출제된 문제가 없습니다.</Text>
          )}
        </Box>
        {isSelectingPartOfPassage ? (
          <Center mt={3}>
            <Button
              colorScheme="teal"
              variant="outline"
              onClick={() => {
                addQuestion();
                SetIsSelectingPartOfPassage(false);
              }}
              isLoading={isLoading}
              mr={3}
              borderRadius="full"
            >
              선택 완료
            </Button>
          </Center>
        ) : (
          <Center mt={3}>
            <Button
              colorScheme="teal"
              onClick={addCard}
              isLoading={isLoading}
              mr={3}
              borderRadius="full"
            >
              문제 출제 유형 선택
            </Button>
          </Center>
        )}
      </Grid>

      {/* Modal for adding a new question */}
      <Modal
        isOpen={isModalOpen && !isSelectingPartOfPassage}
        onClose={closeModal}
        size="6xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>문제 유형 및 보기 설정</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex>
              {/* 왼쪽: 문제 유형 선택 */}
              <Box width="50%" pr={4} maxHeight="500px" overflowY="scroll">
                <Wrap spacing={4} {...group}>
                  {currentTypeData.map((type) => {
                    const radio = getRadioProps({ value: type.type });
                    const colorScheme = typeColors[type.type] || "gray";
                    return (
                      <WrapItem key={type.type}>
                        <RadioCard {...radio} colorScheme={colorScheme}>
                          <Text fontWeight="bold">{type.type}</Text>
                        </RadioCard>
                      </WrapItem>
                    );
                  })}
                </Wrap>
              </Box>

              {/* 오른쪽: 선택된 문제 유형에 대한 설명 */}
              <Box width="50%" pl={4}>
                {typeDetails ? (
                  <Box
                    width="100%"
                    bg="gray.100"
                    borderRadius="md"
                    p={4}
                    maxHeight="500px"
                    overflowY="auto"
                  >
                    <Text fontWeight="bold">유형: {typeDetails.type}</Text>
                    <Text mt={2} fontWeight="bold">
                      설명:
                    </Text>
                    <Text>{typeDetails.policy}</Text>
                    <Text mt={2} fontWeight="bold">
                      문제 예시:
                    </Text>
                    <Text whiteSpace="pre-wrap">{typeDetails.example}</Text>
                  </Box>
                ) : (
                  <Box width="100%" bg="gray.100" borderRadius="md" p={4}>
                    <Text>
                      문제 유형을 선택하면 자세한 설명이 여기에 표시됩니다.
                    </Text>
                  </Box>
                )}
                <Flex direction="column" align="start">
                  {/* 지문의 내용 일부 선택하는 버튼 */}
                  {isShowPartSelectButton && (
                    <Button
                      colorScheme="teal"
                      onClick={() => {
                        setShowPopup(false);
                        SetIsSelectingPartOfPassage(true);
                        SetHilighterColor(typeColors[selectedType]);
                      }}
                      mt={4}
                      borderRadius="full"
                    >
                      지문 중 참조하기
                    </Button>
                  )}
                  {/* 보기 포함 여부 선택 */}
                  <Checkbox
                    mt={4}
                    isChecked={includeSupplement}
                    onChange={handleCheckboxChange}
                  >
                    보기 포함 여부
                  </Checkbox>
                </Flex>
              </Box>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="teal"
              onClick={addQuestion}
              isLoading={isLoading}
              borderRadius="full"
            >
              문제 추가
            </Button>
            <Button
              variant="ghost"
              onClick={closeModal}
              ml={3}
              borderRadius="full"
            >
              취소
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {/* Display added cards */}
      {cards.length > 0 && (
        <Grid templateColumns={{ md: "1fr", lg: "1fr" }} my="26px" gap="24px">
          <Box bg="white" borderWidth="1px" borderRadius="lg" p={3}>
            <Center mb={3}>
              <Heading size="md">출제 문제 유형 예시</Heading>
            </Center>
            <VStack spacing={4}>
              {cards.map((card, index) => (
                <Box
                  key={index}
                  bg="gray.50"
                  borderWidth="1px"
                  borderRadius="lg"
                  p={4}
                  width="100%"
                >
                  <Text fontWeight="bold" mb={2}>
                    문제 유형: {card.type}
                  </Text>
                  <Text fontWeight="bold" mb={2}>
                    유형 설명:
                  </Text>
                  <Text mb={2}>{card.policy}</Text>
                  <Text fontWeight="bold" mb={2}>
                    문제 예시:
                  </Text>
                  <Text mb={2} whiteSpace="pre-wrap">
                    {card.example}
                  </Text>
                  <Text fontWeight="bold" mb={2}>
                    보기 포함 여부: {card.includeSupplement ? "예" : "아니오"}
                  </Text>

                  {card.selectedTexts.length > 0 &&
                    card.selectedTexts.map((text, idx) => (
                      <Text key={idx} mb={2}>
                        참조 {idx + 1}: {text}
                      </Text>
                    ))}

                  <IconButton
                    aria-label="Delete"
                    icon={<MdOutlineDelete />}
                    colorScheme="red"
                    onClick={() => deleteCard(card.id)}
                    size="sm"
                    variant="outline"
                    alignSelf="flex-end"
                  />
                </Box>
              ))}
            </VStack>
            <Center mt={3}>
              <Button
                colorScheme="teal"
                onClick={createQuestion}
                isLoading={isLoading}
                borderRadius="full"
              >
                문제 생성
              </Button>
            </Center>
          </Box>
        </Grid>
      )}

      {/* display generated questions */}
      {generatedQuestions.length > 0 && (
        <Grid templateColumns={{ md: "1fr", lg: "1fr" }} my="26px" gap="24px">
          <Box bg="white" borderWidth="1px" borderRadius="lg" p={3}>
            <Box p={3}>
              <Center>
                <Heading size="md">생성된 문제 및 해설</Heading>
              </Center>
            </Box>
            {generatedQuestions.map((question, index) => {
              // 편집상태이면 편집모드로 렌더링
              // 아니면 일반모드로 렌더링
              return editMode[index] ? (
                <Box
                  key={index}
                  bg="gray.200"
                  borderWidth="1px"
                  borderRadius="lg"
                  p={3}
                  mb={4}
                >
                  <Checkbox
                    colorScheme="teal"
                    size="lg"
                    mb={2}
                    onChange={(e) => handleGeneratedCheckboxChange(e, index)}
                  />
                  <Heading size="sm">
                    문제 {index + 1}
                    <Input
                      defaultValue={question.question}
                      backgroundColor="white"
                      onBlur={(e) => {
                        updateQuestionProperty(
                          index,
                          "question",
                          e.target.value
                        );
                      }}
                    />
                  </Heading>
                  <Text width="100%">
                    보기
                    <Textarea
                      bg="white"
                      p={3}
                      mt={3}
                      defaultValue={question.supplement}
                      onBlur={(e) => {
                        updateQuestionProperty(
                          index,
                          "supplement",
                          e.target.value
                        );
                      }}
                    />
                  </Text>
                  <VStack spacing={2} mt={2} alignItems="start">
                    <Text width="100%">
                      1.
                      <Input
                        defaultValue={question.choice_1}
                        backgroundColor="white"
                        onBlur={(e) => {
                          updateQuestionProperty(
                            index,
                            "choice_1",
                            e.target.value
                          );
                        }}
                      />
                    </Text>
                    <Text width="100%">
                      2.
                      <Input
                        defaultValue={question.choice_2}
                        backgroundColor="white"
                        onBlur={(e) => {
                          updateQuestionProperty(
                            index,
                            "choice_2",
                            e.target.value
                          );
                        }}
                      />
                    </Text>
                    <Text width="100%">
                      3.
                      <Input
                        defaultValue={question.choice_3}
                        backgroundColor="white"
                        onBlur={(e) => {
                          updateQuestionProperty(
                            index,
                            "choice_3",
                            e.target.value
                          );
                        }}
                      />
                    </Text>
                    <Text width="100%">
                      4.
                      <Input
                        defaultValue={question.choice_4}
                        backgroundColor="white"
                        onBlur={(e) => {
                          updateQuestionProperty(
                            index,
                            "choice_4",
                            e.target.value
                          );
                        }}
                      />
                    </Text>
                    <Text width="100%">
                      5.
                      <Input
                        defaultValue={question.choice_5}
                        backgroundColor="white"
                        onBlur={(e) => {
                          updateQuestionProperty(
                            index,
                            "choice_5",
                            e.target.value
                          );
                        }}
                      />
                    </Text>
                    <Text fontWeight="bold">
                      정답:
                      <Input
                        defaultValue={generatedSolutions[index].answer}
                        backgroundColor="white"
                        onBlur={(e) => {
                          updateSolutionPropery(
                            index,
                            "answer",
                            e.target.value
                          );
                        }}
                      />
                    </Text>
                    <Text mt={2} fontWeight="bold">
                      해설:
                    </Text>
                    <Input
                      defaultValue={generatedSolutions[index].explanation}
                      backgroundColor="white"
                      onBlur={(e) => {
                        updateSolutionPropery(
                          index,
                          "explanation",
                          e.target.value
                        );
                      }}
                    />
                  </VStack>
                  <IconButton
                    aria-label="Check"
                    onClick={() => {
                      exitEditMode(index);
                      // saveEditedQuestion(index);
                    }}
                    icon={<CheckIcon />}
                    size="sm"
                    alignSelf="flex-start"
                    marginTop="10px"
                  />
                </Box>
              ) : (
                <Box
                  key={index}
                  bg="gray.50"
                  borderWidth="1px"
                  borderRadius="lg"
                  p={3}
                  mb={4}
                >
                  <Checkbox
                    colorScheme="teal"
                    size="lg"
                    mb={2}
                    onChange={(e) => handleGeneratedCheckboxChange(e, index)}
                  />
                  <Heading size="sm">
                    문제 {index + 1}: {question.question}
                  </Heading>
                  {question.supplement ? (
                    <Box
                      bg="white"
                      borderWidth="1px"
                      borderRadius="lg"
                      p={3}
                      whiteSpace="pre-wrap"
                      mt={3}
                    >
                      {question.supplement}
                    </Box>
                  ) : (
                    <></>
                  )}
                  <VStack spacing={2} mt={2} alignItems="start">
                    <Text>1. {question.choice_1}</Text>
                    <Text>2. {question.choice_2}</Text>
                    <Text>3. {question.choice_3}</Text>
                    <Text>4. {question.choice_4}</Text>
                    <Text>5. {question.choice_5}</Text>
                    <Text fontWeight="bold">
                      정답: {generatedSolutions[index].answer}
                    </Text>
                    <Text mt={2} fontWeight="bold">
                      해설:
                    </Text>
                    <Text>{generatedSolutions[index].explanation}</Text>
                  </VStack>
                  <IconButton
                    aria-label="Edit"
                    onClick={() => enterEditMode(index)}
                    icon={<EditIcon />}
                    size="sm"
                    alignSelf="flex-start"
                    marginTop="10px"
                  />
                </Box>
              );
            })}
            <Box
              bg="white"
              borderWidth="1px"
              borderRadius="xl"
              p={4}
              mt={0}
              width={{ base: "100%", md: "100%" }}
            >
              <Text fontWeight="bold">별점 (5점 만점):</Text>
              <HStack>
                {[1, 2, 3, 4, 5].map((star) => (
                  <Box
                    key={star}
                    position="relative"
                    display="inline-block"
                    onMouseDown={(e) => {
                      const box = e.target.getBoundingClientRect();
                      const clickX = e.clientX - box.left;
                      const starWidth = box.width;

                      // Determine if click was on left or right side of the star
                      if (clickX < starWidth / 2) {
                        setRating(star - 0.5); // Half star
                      } else {
                        setRating(star); // Full star
                      }
                    }}
                  >
                    <IconButton
                      icon={
                        rating >= star ? (
                          <FaStar />
                        ) : rating >= star - 0.5 ? (
                          <FaStarHalfAlt />
                        ) : (
                          <FaRegStar />
                        )
                      }
                      variant="ghost"
                      aria-label={`Rate ${star}`}
                    />
                  </Box>
                ))}
              </HStack>

              <Text fontWeight="bold" mt={2} mb={1}>
                추가 의견:
              </Text>
              <Textarea
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                placeholder="의견을 입력해주세요."
                bg="white"
                borderRadius="xl"
              />

              <Checkbox
                isChecked={sendFeedback}
                onChange={(e) => setSendFeedback(e.target.checked)}
                colorScheme="teal"
                mt={2}
              >
                별점과 의견을 지문과 함께 저장합니다.
              </Checkbox>
            </Box>

            <Center>
              <Button
                colorScheme="teal"
                isLoading={isLoading}
                isDisabled={checkedBoxList.length == 0}
                onClick={saveSelectedQuestions}
                borderRadius="full"
              >
                선택된 문제 저장
              </Button>
            </Center>
          </Box>
        </Grid>
      )}
    </Flex>
  );
}
