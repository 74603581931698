import {
  Flex,
  Box,
  Heading,
  Text,
  VStack,
  List,
  ListItem,
  SimpleGrid,
  GridItem,
  Skeleton,
} from "@chakra-ui/react";
import { useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

export default function ProblemBankResult() {
  const location = useLocation();
  const { workbook, passages } = location.state || { workbook: { questions: [] }, passages: {} };

  const [combinedContent, setCombinedContent] = useState([]);

  useEffect(() => {
    // 문제와 지문을 순서에 맞게 혼합
    const combined = [];
    const seenPassages = new Set();

    workbook.questions.forEach(question => {
      const passageId = question.passage;

      // passages[passageId]가 존재하는지 확인
      if (passageId && !seenPassages.has(passageId) && passages[passageId]) {
        const passageData = passages[passageId];
        const paragraphs = passageData.contents?.split('\n').filter(p => p.trim() !== ''); // 문단으로 나누기
        paragraphs.forEach((para, index) => {
          combined.push({ type: 'passage', data: { ...passageData, contents: para }, paragraphIndex: index });
        });
        seenPassages.add(passageId);
      }
  

      combined.push({ type: 'question', data: question });
    });

    setCombinedContent(combined);
  }, [workbook, passages]);

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }} alignItems="center">
      <Box bg="white" borderWidth="1px" borderRadius="lg" p={5} width={{ base: "100%", md: "80%" }}>
        <Heading size="md" mb={5} textAlign="center">{workbook.title}</Heading>
        {combinedContent.length > 0 ? (
          <SimpleGrid columns={1} spacing={10} w="full">
            {combinedContent.map((item, index) => (
              <GridItem key={index}>
                <Flex justifyContent="space-between">
                  <Box w="50%" p={4} borderWidth="1px" borderRadius="lg" mr={4}>
                    {item.type === 'passage' ? (
                      <>
                        <Heading size="sm" mb={3}>지문: {item.data?.title || '지문 제목'}</Heading>
                        <Text whiteSpace="pre-wrap">{item.data?.contents || '지문 내용이 없습니다.'}</Text>
                      </>
                    ) : (
                      <>
                        <Heading size="sm" mb={3}>문제: {item.data.question}</Heading>
                        <VStack align="start" spacing={2}>
                          <Text>1. {item.data.choice_1}</Text>
                          <Text>2. {item.data.choice_2}</Text>
                          <Text>3. {item.data.choice_3}</Text>
                          <Text>4. {item.data.choice_4}</Text>
                          <Text>5. {item.data.choice_5}</Text>
                        </VStack>
                      </>
                    )}
                  </Box>
                  {item.type === 'question' && (
                    <Box w="50%" p={4} borderWidth="1px" borderRadius="lg">
                      <Heading size="sm" mb={3}>지문: {passages[item.data.passage]?.title || '지문 제목'}</Heading>
                      <Text whiteSpace="pre-wrap">{passages[item.data.passage]?.contents || '지문 내용이 없습니다.'}</Text>
                    </Box>
                  )}
                </Flex>
              </GridItem>
            ))}
          </SimpleGrid>
        ) : (
          <SimpleGrid columns={1} spacing={10} w="full">
            <GridItem>
              <Flex justifyContent="space-between">
                <Box w="50%" p={4} borderWidth="1px" borderRadius="lg" mr={4}>
                  <Skeleton height="150px" mb={4} />
                  <Skeleton height="20px" width="80%" mb={2} />
                  <Skeleton height="20px" width="60%" />
                </Box>
                <Box w="50%" p={4} borderWidth="1px" borderRadius="lg">
                  <Skeleton height="150px" mb={4} />
                  <Skeleton height="20px" width="80%" mb={2} />
                  <Skeleton height="20px" width="60%" />
                </Box>
              </Flex>
            </GridItem>
          </SimpleGrid>
        )}
      </Box>
    </Flex>
  );
}
