import { Box, Text, HStack } from "@chakra-ui/react";

const WorkComparisonSection = ({ retrievedWork }) => {
  return (
    <HStack
      spacing={0}
      display="flex"
      width="100%"
      height="100%"
      justifyContent="center" // Centers the boxes horizontally
      alignItems="flex-start"
      p={0}
    >
      {/* Retrieved Work (나) */}
      <Box
        width="50%" // Adjusted width since original work is removed
        whiteSpace="pre-wrap"
        p={0}
        m={0}
        display="flex"
        flexDirection="column"
        alignSelf="center"
      >
        <Text fontWeight="bold" my={0}>
          (나)
        </Text>
        <Text my={0}>{retrievedWork.content || "작품 내용"}</Text>
        <Text textAlign="right" my={0}>
          - {retrievedWork.author}, 「{retrievedWork.title}」 -
        </Text>
      </Box>

      {/* Vertial Line Separator */}
      <Box width="1px" height="auto" alignSelf="stretch" bg="gray.300" mx={4} />

      {/* Comparison (보기) */}
      <Box
        width="50%" // Adjusted width since original work is removed
        p={0}
        m={0}
        display="flex"
        flexDirection="column"
      >
        <Text fontWeight="bold" justifyContent="center">
          &lt;보기&gt;
        </Text>
        <Text>{retrievedWork.supplement.comparison || "비교 내용"}</Text>
      </Box>
    </HStack>
  );
};

export default WorkComparisonSection;
