import {
    Flex,
    Grid,
    Box,
    Center,
    Button,
    Heading,
    useToast,
    Input,
    Select,
    Textarea,
    FormControl,
    FormLabel,
    HStack,
    VStack,
    IconButton,
  } from "@chakra-ui/react";
  import { AddIcon, CloseIcon } from "@chakra-ui/icons";
  import React, { SetStateAction, useCallback, useEffect, useState } from "react";
  

export default function QuestionTitle(props) {
    

return(
  <div>
    <Input
      placeholder="문제를 입력하세요"
      onBlur ={(e) => { 
        props.handleDataAdd({id:props.id, statement: e.target.value});
        }}
    />
    </div>
);

}
