// Chakra imports
import { ChakraProvider, Portal, useDisclosure } from "@chakra-ui/react";
import Configurator from "components/Configurator/Configurator";
import Footer from "../components/Footer/Footer.js";
// Layout components
import Sidebar from "../components/Sidebar/Sidebar.js";
import React, { useState } from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import routeGroup from "../routes.js";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
// Custom Chakra theme
import theme from "theme/theme.js";
import FixedPlugin from "../components/FixedPlugin/FixedPlugin.js";
// Custom components
import MainPanel from "../components/Layout/MainPanel.js";
import Navbar from "../components/Navbars/Navbar.js";
import PoemExtractLiteratureDashboard from "../views/Dashboard/Dashboard/PoemExtractLiteratureDashboard.js";

export default function PoemExtractLiteraturePage(props) {
  const { ...rest } = props;
  // states and functions
  const [sidebarVariant, setSidebarVariant] = useState("transparent");
  const [fixed, setFixed] = useState(false);
  const [routes, setRoutes] = useState(routeGroup);
  const [logoText, setLogoText] = useState("Lambda Services");
  /*
  getActiveRoute 함수는 주어진 routes 배열을 순회하며 현재 활성화된 라우트의 이름을 반환합니다. 이 함수는 재귀적으로 호출되어 라우트의 계층 구조를 탐색합니다.
		1. routes 배열을 순회합니다.
		2. 각 라우트에 collapse 속성이 있는지 확인합니다.collapse 속성이 있다면, 해당 라우트는 하위 라우트를 가지고 있습니다. 이 경우, getActiveRoute 함수를 재귀적으로 호출하여 하위 라우트 중 활성화된 라우트를 찾습니다. 활성화된 라우트를 찾으면 그 이름을 반환합니다.
		3. collapse 속성이 없고 category 속성이 있는 경우에도 마찬가지로 하위 라우트를 탐색합니다.
		4. collapse와 category 속성이 모두 없는 경우, 현재 라우트의 layout과 path가 현재 URL에 포함되어 있는지 확인합니다. 포함되어 있다면, 해당 라우트가 활성화된 라우트이므로 그 이름을 반환합니다.
		5. 모든 라우트를 순회한 후에도 활성화된 라우트를 찾지 못하면, 기본 텍스트인 'Default Brand Text' -> 해설지 생성 를 반환합니다.
		이 함수는 주로 네비게이션 바에서 현재 활성화된 라우트를 표시하는 데 사용됩니다.
	*/

  function getActiveRoute(routes) {
    let activeRoute = "문학 지문 생성";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else if (routes[i].category) {
        let categoryActiveRoute = getActiveRoute(routes[i].views);
        if (categoryActiveRoute !== activeRoute) {
          return categoryActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  }
  // This changes navbar state(fixed or not)
  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].category) {
        let categoryActiveNavbar = getActiveNavbar(routes[i].views);
        if (categoryActiveNavbar !== activeNavbar) {
          return categoryActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          if (routes[i].secondaryNavbar) {
            return routes[i].secondaryNavbar;
          }
        }
      }
    }
    return activeNavbar;
  };

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.category === "account") {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            element={prop.element}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const { isOpen, onOpen, onClose } = useDisclosure();
  document.documentElement.dir = "ltr";
  // // Chakra Color Mode
  return (
    <ChakraProvider theme={theme} resetCss={false}>
      {/* Sidebar region */}
      <Sidebar
        routes={routes}
        logoText={logoText}
        display="none"
        sidebarVariant={sidebarVariant}
        {...rest}
      ></Sidebar>

      {/* Content region */}
      <MainPanel w={{ base: "100%", xl: "calc(100% - 275px)" }}>
        {/* Navbar region */}
        <Portal>
          <Navbar
            onOpen={onOpen}
            logoText={logoText}
            brandText={getActiveRoute(routes)}
            secondary={getActiveNavbar(routes)}
            fixed={fixed}
            routes={routes}
            {...rest}
          />
        </Portal>

        {/* Main Content region */}
        <PoemExtractLiteratureDashboard />

        {/* Footer region */}
        <Footer />

        <Portal>
          <FixedPlugin
            secondary={getActiveNavbar(routes)}
            fixed={fixed}
            onOpen={onOpen}
          />
        </Portal>
        <Configurator
          secondary={getActiveNavbar(routes)}
          isOpen={isOpen}
          onClose={onClose}
          isChecked={fixed}
          onSwitch={(value) => {
            setFixed(value);
          }}
          onOpaque={() => setSidebarVariant("opaque")}
          onTransparent={() => setSidebarVariant("transparent")}
        />
      </MainPanel>
    </ChakraProvider>
  );
}
