import React, { useState } from 'react';
import {
  Flex,
  Box,
  Heading,
  Text,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Button,
  Checkbox,
  Grid,
  Card,
  CardBody,
  useRadio,
  useRadioGroup,
  Center,
  Input,
  Tag,
  TagCloseButton,
  TagLabel,
  Wrap,
  WrapItem,
  Textarea,
  useColorModeValue,
  useToast,
  Select,
} from "@chakra-ui/react";
import axios from "axios";
import apiClient from "../../../api/axiosInstance"; // 생성한 Axios 인스턴스를 import
import { useNavigate } from 'react-router-dom';
import { FaShuffle } from "react-icons/fa6";


// Radio 버튼 컴포넌트
function RadioCard(props) {
  const { getInputProps, getCheckboxProps } = useRadio(props);
  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label" mb={4}>
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="full" // 둥근 모서리 적용
        _checked={{
          bg: "teal.600",
          color: "white",
          borderColor: "teal.600",
        }}
        _focus={{
          boxShadow: "outline",
        }}
        px={5}
        py={3}
      >
        {props.children}
      </Box>
    </Box>
  );
}

export default function CreatePassage() {
  const [length, setLength] = useState(1000); // 기본 텍스트 길이
  const [isStructureCheck, setIsStructureCheck] = useState(false);
  const [category, setCategory] = useState("독서 이론"); // 기본 카테고리
  const [keywords, setKeywords] = useState([]); // 저장된 키워드
  const [keywordType, setKeywordType] = useState("정의");
  const [keywordInput, setKeywordInput] = useState(""); // 키워드 입력 값
  const [isLoading, setIsLoading] = useState(false); // 로딩 상태
  const [generatedPassage, setGeneratedPassage] = useState(null); // 생성된 지문
  const [title, setTitle] = useState(""); // 지문 제목
  const [paragraphs, setParagraphs] = useState([]); // 문단 관리
  const [passageCount, setPassageCount] = useState("1"); // 지문 개수
  const [isLoadingTitle, setIsLoadingTitle] = useState(false);
  const [isLoadingMultiTitle, setIsLoadingMultiTitle] = useState(false);
  const [isLoadingKeywords, setIsLoadingKeywords] = useState(false);
  const navigate = useNavigate();
  const [paragraphKeywords, setParagraphKeywords] = useState([]);
  const [paragraphKeywordInputs, setParagraphKeywordInputs] = useState([]);
  const toast = useToast();
  const token = localStorage.getItem("token");
  const [multiTitles, setMultiTitles] = useState({ titleA: "", titleB: "" }); // 복수 지문 제목
  const [isEditing, setIsEditing] = useState(false);
  const [paragraphKeywordTypes, setParagraphKeywordTypes] = useState([]);


  
  // 색상 모드에 따라 다른 색상을 사용
  const bgColor = useColorModeValue("white", "gray.700");
  const textColor = useColorModeValue("gray.600", "gray.300");
  const inputBg = useColorModeValue("gray.50", "gray.800");

   // 주제 추천 버튼 핸들러
   const handleRecommendTitle = async () => {
    setIsLoadingTitle(true);
    try {
      const response = await apiClient.get(
        '/problem_generator/recommend_title/',
        { params: { category } }
      );
      setTitle(response.data.title);
      toast({
        title: "주제 추천 완료",
        description: "추천된 주제가 설정되었습니다.",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "주제 추천 실패",
        description: "추천된 주제를 가져오는 중 오류가 발생했습니다.",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    } finally {
      setIsLoadingTitle(false);
    }
  };

  const handleRecommendTitles = async () => {
    setIsLoadingMultiTitle(true);
    try {
      const response = await apiClient.get(
        '/problem_generator/multi_titles_recommendation/',
        { params: { category } }
      );
      setMultiTitles({
        titleA: response.data.titleA,
        titleB: response.data.titleB,
      });
    } catch (error) {
      toast({
        title: "추천 실패",
        description: "주제 추천 중 오류가 발생했습니다.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    } finally {
      setIsLoadingMultiTitle(false);
    }
  };

  const handleRecommendKeywords = async () => {
    if (!title) {
      alert("먼저 주제를 설정하세요.");
      return;
    }
    setIsLoadingKeywords(true);
    try {
      const response = await apiClient.get(
        '/problem_generator/recommend_keywords/',
        { params: { category, title } }
      );
      setKeywords(response.data.keywords.map(kw => ({ keyword: kw, type: "정의" })));
      toast({
        title: "키워드 추천 완료",
        description: "추천된 키워드가 설정되었습니다.",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "키워드 추천 실패",
        description: "추천된 키워드를 가져오는 중 오류가 발생했습니다.",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    } finally {
      setIsLoadingKeywords(false);
    }
  };

  const handleGenerate = () => {
    if (passageCount === "1" && title.trim() === "") {
      alert("지문 주제를 입력해주세요.");
      return;
    }
    if (passageCount === "2" && (multiTitles.titleA.trim() === "" || multiTitles.titleB.trim() === "")) {
      alert("두 개의 지문 주제를 입력해주세요.");
      return;
    }

    setIsLoading(true);

    const data = {
      length,
      isStructureCheck,
      category,
      keywords, // 키워드를 서버로 전송
      passageCount: passageCount === "2" ? 2 : 1,
      title: passageCount === "1" ? title : null,
      multiTitles: passageCount === "2" ? multiTitles : null,
    };

    apiClient.post('/problem_generator/generate_passage/', data)
      .then(response => {
        const passage = response.data.passage.contents;
        const splitParagraphs = passage.split('\n').filter(p => p.trim() !== ""); // 문단으로 나누기
        setParagraphs(splitParagraphs);
        setParagraphKeywords(new Array(splitParagraphs.length).fill([]));
        setParagraphKeywordInputs(new Array(splitParagraphs.length).fill(""));
        setGeneratedPassage(response.data); // 생성된 지문 저장
        setIsLoading(false);
      })
      .catch(error => {
        setGeneratedPassage(null);  // 오류 시 지문 초기화
        setIsLoading(false);
        alert("지문 생성 중 오류가 발생했습니다. 다시 시도해주세요.");
        console.error("Error generating passage:", error);
      });
  };

  const handleCreateQuestion = () => {
    // 수정된 문단을 합쳐서 하나의 텍스트로 만듭니다.
    const updatedPassage = paragraphs.join('\n\n\n');
    const token = localStorage.getItem("token"); // 토큰 가져오기
  
    apiClient.put(
      `/problem_generator/passages/${generatedPassage.id}`,
      {
        id: generatedPassage.id, // passage ID 추가
        contents: updatedPassage, // 수정된 지문 데이터
        title: generatedPassage.passage.title, // 제목
        category: category // 카테고리
      }
    )
    .then(() => {
      // 업데이트가 성공하면 페이지 이동
      navigate('/create-question', { 
        state: { 
          passage_id: generatedPassage.id,
          title: generatedPassage.passage.title, 
          category: category,
          contents: updatedPassage // 수정된 지문을 사용
        } 
      });
    })
    .catch(error => {
      console.error("Error updating passage:", error);
      
      // 에러 발생 시 토큰 삭제 및 로그아웃 처리
      alert("지문 업데이트 중 오류가 발생했습니다. 다시 로그인해 주세요.");
      localStorage.removeItem("token"); // 토큰 삭제
      navigate('/signin'); // 로그인 페이지로 리다이렉트
    });
  };
  
  
  const handleKeywordInput = (e) => {
    setKeywordInput(e.target.value);
  };



  const handleSaveClick = async () => {
    setIsEditing(false);

    // 전체 지문을 저장하기 위해 paragraphs를 하나의 텍스트로 합칩니다.
    const fullText = paragraphs.join("\n\n");

    try {
      await apiClient.put(
        `/problem_generator/passages/${generatedPassage.id}`,
        {
          contents: fullText,
        }
      );
      toast({
        title: "지문 저장 성공",
        description: "전체 지문이 성공적으로 저장되었습니다.",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    } catch (error) {
      console.error("Error updating passage", error);
      toast({
        title: "지문 저장 실패",
        description: "지문 저장 중 오류가 발생했습니다.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    }
  };
  
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // 기본 동작 방지
      if (keywordInput.trim() !== "") {
        handleAddKeyword();
      }
    }
  };
  
  const getCharacterCount = (text) => {
    return text.length;
  };

  // 전체 글자 수 계산 함수
  const getTotalCharacterCount = () => {
    return paragraphs.reduce((total, paragraph) => total + getCharacterCount(paragraph), 0);
  };
  
  const handleAddKeyword = () => {
    const trimmedKeyword = keywordInput.trim();

    if (trimmedKeyword === "") {
      alert("키워드를 입력하세요.");
      return;
    }

    if (keywords.find(kw => kw.keyword === trimmedKeyword && kw.type === keywordType)) {
      alert("같은 키워드와 타입이 이미 존재합니다.");
      return;
    }

    setKeywords([...keywords, { keyword: trimmedKeyword, type: keywordType }]);
    setKeywordInput("");
    setKeywordType("정의");
  };

  const handleRemoveKeyword = (keywordToRemove) => {
    setKeywords(keywords.filter(kw => kw.keyword !== keywordToRemove.keyword || kw.type !== keywordToRemove.type));
  };

  const handleParagraphChange = (index, newValue) => {
    const newParagraphs = [...paragraphs];
    newParagraphs[index] = newValue;
    setParagraphs(newParagraphs);
  };

  const handleParagraphKeywordInput = (index, value) => {
    const newInputs = [...paragraphKeywordInputs];
    newInputs[index] = value;
    setParagraphKeywordInputs(newInputs);
  };

  const handleParagraphKeywordTypeChange = (index, value) => {
    const newTypes = [...paragraphKeywordTypes];
    newTypes[index] = value;
    setParagraphKeywordTypes(newTypes);
  };
  
  const handleAddParagraphKeyword = (index) => {
    const keyword = paragraphKeywordInputs[index].trim();
    const keywordType = paragraphKeywordTypes[index] || "정의"; // 기본값을 "정의"로 설정
    if (keyword === "") return;
  
    // 현재 문단의 키워드 배열을 안전하게 복사
    const existingKeywords = paragraphKeywords[index] ? [...paragraphKeywords[index]] : [];
  
    // 중복 검사: 같은 키워드와 타입이 있으면 중단
    const isDuplicate = existingKeywords.some(
      (item) => item.keyword === keyword && item.type === keywordType
    );
    if (isDuplicate) {
      alert("해당 키워드가 이미 존재합니다.");
      return;
    }
  
    // 새로운 키워드 배열 생성
    const newParagraphKeywords = [...paragraphKeywords];
    newParagraphKeywords[index] = [...existingKeywords, { keyword, type: keywordType }];
    setParagraphKeywords(newParagraphKeywords);
  
    // 입력 필드와 타입 초기화
    const newInputs = [...paragraphKeywordInputs];
    newInputs[index] = "";
    setParagraphKeywordInputs(newInputs);
  
    const newTypes = [...paragraphKeywordTypes];
    newTypes[index] = "정의";
    setParagraphKeywordTypes(newTypes);
  };
  
  
  const handleRemoveParagraphKeyword = (paragraphIndex, keyword) => {
    const newParagraphKeywords = [...paragraphKeywords];

    // 해당 문단에서 특정 키워드 객체를 제거
    newParagraphKeywords[paragraphIndex] = newParagraphKeywords[
      paragraphIndex
    ].filter(
      (item) => item.keyword !== keyword // 객체의 keyword 속성을 비교
    );

    setParagraphKeywords(newParagraphKeywords);
  };
  
  
  // 문단 재생성 함수 수정
  const handleRegenerateParagraph = (index) => {
    setIsLoading(true);
    apiClient.post(`/problem_generator/regenerate_paragraph/`, {
      paragraph: paragraphs[index],
      keywords: paragraphKeywords[index]
    })
      .then(response => {
        const newParagraphs = [...paragraphs];
        newParagraphs[index] = response.data.new_paragraph;
        setParagraphs(newParagraphs);
        setIsLoading(false);
      })
      .catch(error => {
        setIsLoading(false);
        toast({
          title: "문단 재생성 실패",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };

  const options = ["독서 이론", "사회(법)", "사회(경제)", "사회(일반)", "인문", "예술(일반)", "예술(건축)", "기술", "과학(생물)", "과학(화학)", "과학(물리)", "과학(천체물리)"];

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "category",
    defaultValue: "독서 이론",
    onChange: setCategory,
  });

  const { getRootProps: getPassageRootProps, getRadioProps: getPassageRadioProps } = useRadioGroup({
    name: "passageCount",
    defaultValue: "1",
    onChange: setPassageCount,
  });

  const group = getRootProps();
  const passageGroup = getPassageRootProps();


  return (
    <Flex
    flexDirection="column"
    pt={{ base: "120px", md: "75px" }}
    px={{ base: 4, md: 8 }}
  >
    <Grid
      templateColumns={{ md: "1fr", lg: "1fr" }}
      templateRows={{ md: "1fr auto", lg: "1fr" }}
      my="26px"
      gap="24px"
    >
      <Box mb={8}>
        <Heading size="lg" color="teal.600">
        지문 생성하기
        </Heading>
        <Text color="gray.600" mt={2}>
          원하시는 지문 옵션을 선택하세요
        </Text>
      </Box>
      <Box bg="white" borderWidth="1px" borderRadius="xl" p={5} mb={6} boxShadow="md">

      <Wrap spacing={4} justify="center" mb={4}>
            {options.map((value) => {
              const radio = getRadioProps({ value });
              return (
                <WrapItem key={value}>
                  <RadioCard {...radio}>
                    <Center>{value}</Center>
                  </RadioCard>
                </WrapItem>
              );
            })}
          </Wrap>
  {passageCount === "1" ? (
    <Flex mb={2}>
      <Input
        borderWidth="2px"
        borderColor="teal.500"
        placeholder="지문 주제를 입력하세요"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        mb={4}
        bg={inputBg}
        borderRadius="full"
        mr={2}  // 간격 추가
      />
      <Button
        leftIcon={<FaShuffle />} 
        borderRadius="full"
        onClick={handleRecommendTitle}
        colorScheme="orange"
        mb={4}
        isLoading={isLoadingTitle} // 로딩 상태
      >
        주제 추천
      </Button>
    </Flex>
  ) : (
    <>
    <Flex mb={2}>
      <Input
        borderWidth="2px"
        borderColor="teal.500"
        placeholder="(가) 지문 주제를 입력하세요"
        value={multiTitles.titleA}
        onChange={(e) => setMultiTitles({ ...multiTitles, titleA: e.target.value })}
        mb={4}
        borderRadius="full"
        mr={2}
        width="87%"
      />
      <Button 
        leftIcon={<FaShuffle />} 
        borderRadius="full" 
        onClick={handleRecommendTitles} 
        colorScheme="orange" 
        mb={4}
        isLoading={isLoadingMultiTitle} // 로딩 상태
      >
        주제 추천
      </Button>
      </Flex>
      <Flex mb={2}>
      <Input
        borderWidth="2px"
        borderColor="teal.500"
        placeholder="(나) 지문 주제를 입력하세요"
        value={multiTitles.titleB}
        onChange={(e) => setMultiTitles({ ...multiTitles, titleB: e.target.value })}
        mb={4}
        borderRadius="full"
        mr={2}
        width="87%"
      />
       </Flex>
  </>
  )}
          <Wrap spacing={4} justify="center" mb={4} {...passageGroup}>
            {["1", "2"].map((value) => {
              const radio = getPassageRadioProps({ value });
              return (
                <WrapItem key={value}>
                  <RadioCard {...radio}>
                    <Center>{value === "1" ? "단일 지문" : "복수 지문"}</Center>
                  </RadioCard>
                </WrapItem>
              );
            })}
          </Wrap>

          <Text mb={2} color={textColor}>텍스트 길이</Text>
          <Slider
            aria-label="text-length-slider"
            defaultValue={1000}
            min={300}
            max={3000}
            step={100}
            onChange={(val) => setLength(val)}
            colorScheme="teal"
            mb={4}
          >
            <SliderTrack>
              <SliderFilledTrack />
            </SliderTrack>
            <SliderThumb boxSize={6} borderRadius="full">
              <Box color="teal" />
            </SliderThumb>
          </Slider>
          <Text fontSize="sm" mb={4} color={textColor}>{length}자</Text>
          
          <Flex>
          <Input
              value={keywordInput}
              onChange={handleKeywordInput}
              onKeyDown={handleKeyDown}  // 엔터 키 입력 처리
              placeholder="키워드를 입력하세요"
              mr={2}
              bg={inputBg}
              borderRadius="full"
              // width="40%"
            />
            <Select
            placeholder="키워드 타입 선택"
            value={keywordType}
            onChange={(e) => setKeywordType(e.target.value)}
            width="10%"
            mr={2}
            borderRadius="full"
          >
            <option value="정의">정의</option>
            <option value="예시">예시</option>
            <option value="상세 설명">상세 설명</option>
          </Select>
            <Button onClick={handleAddKeyword} colorScheme="teal" isDisabled={keywordInput.trim() === ""} borderRadius="full" mr={2}>
              추가
            </Button>
            <Button leftIcon={<FaShuffle />} onClick={handleRecommendKeywords} colorScheme="orange" borderRadius="full" width="15%" isLoading={isLoadingKeywords} >
            키워드 추천
          </Button>
          </Flex>
          <Wrap mt={4}>
          {keywords.map((item, index) => (
            <WrapItem key={index}>
              <Tag colorScheme="teal" borderRadius="full">
                <TagLabel>{item.keyword} ({item.type})</TagLabel>
                <TagCloseButton onClick={() => handleRemoveKeyword(item)} />
              </Tag>
            </WrapItem>
          ))}
        </Wrap>
          <Center>
          <Button
            colorScheme="teal"
            onClick={handleGenerate}
            width="20%"
            isLoading={isLoading}
            loadingText="지문 생성 중..."
            mt={6}
            borderRadius="full"
          >
            지문 생성
          </Button>
          </Center>
        </Box>
        {paragraphs.length > 0 ? (
      <Box bg="white" borderWidth="1px" borderRadius="xl" p={5} mb={6} boxShadow="md">
      <Flex justifyContent="space-between" alignItems="center" padding={3}>
      <Heading size="md" mb={4}>생성된 지문</Heading>
          {isEditing ? (
            <Button 
            borderRadius="full"
            colorScheme="teal" onClick={handleSaveClick}>
              전체 지문 저장
            </Button>
          ) : (
            <Button
             borderRadius="full"
             onClick={() => setIsEditing(!isEditing)}>
              수정
            </Button>
          )}
        </Flex>
        {/* 전체 글자 수 통계 추가 */}
  <Box mb={4} p={4} bg="gray.50" borderRadius="lg" textAlign="center">
    <Text fontSize="sm">전체 글자 수</Text>
    <Text fontSize="2xl" fontWeight="bold" color="teal.600">
      {getTotalCharacterCount()}자
    </Text>
  </Box>
        {isEditing === false ? (
        <Text whiteSpace="pre-wrap">{paragraphs.join('\n\n\n')}</Text>
      ) : (
            paragraphs.map((paragraph, index) => (
  <Box key={index} mb={4} p={4} borderWidth="1px" borderRadius="lg">
    <Text mb={2} fontWeight="bold">문단 {index + 1}</Text>
    <Textarea
      value={paragraph}
      onChange={(e) => handleParagraphChange(index, e.target.value)}
      mb={3}
      minHeight="100px"
    />
    
    <Flex mb={2}>
      <Input
        placeholder="문단 키워드 입력"
        borderRadius="full"
        value={paragraphKeywordInputs[index]}
        onChange={(e) =>
          handleParagraphKeywordInput(index, e.target.value)
        }
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault(); // 기본 Enter 동작을 막기
            handleAddParagraphKeyword(index);
            handleParagraphKeywordInput(index, ""); // 입력 필드를 초기화
          }
        }}
        mr={2}
      />
      <Select
        placeholder="키워드 타입 선택"
        value={paragraphKeywordTypes[index] || "정의"}
        onChange={(e) =>
          handleParagraphKeywordTypeChange(index, e.target.value)
        }
        width="30%"
        mr={2}
        borderRadius="full"
      >
        <option value="정의">정의</option>
        <option value="예시">예시</option>
        <option value="상세 설명">상세 설명</option>

      </Select>
      <Button
        colorScheme="teal"
        onClick={() => handleAddParagraphKeyword(index)}
        borderRadius="full"
      >
        추가
      </Button>
    </Flex>

    <Wrap mb={3}>
      {paragraphKeywords[index] &&
        paragraphKeywords[index].map((item, kidx) => (
          <WrapItem key={kidx}>
            <Tag colorScheme="teal" borderRadius="full">
              <TagLabel>
                {item.keyword} ({item.type})
              </TagLabel>
              <TagCloseButton
                onClick={() =>
                  handleRemoveParagraphKeyword(index, item.keyword)
                }
              />
            </Tag>
          </WrapItem>
        ))}
    </Wrap>

    <Button
      colorScheme="teal"
      onClick={() => handleRegenerateParagraph(index)}
      isLoading={isLoading}
      borderRadius="full"
    >
      문단 재생성
    </Button>
  </Box>
        )))}
            <Button
              mt={4}
              colorScheme="teal"
              width="100%"
              onClick={handleCreateQuestion}
              borderRadius="full"
            >
              문제 생성하기
            </Button>
          </Box>
        ) : (
          <Text mt={5} textAlign="center" color={textColor}>생성된 지문이 없습니다. 제목과 키워드를 입력하고 지문을 생성하세요.</Text>
        )}
      </Grid>
    </Flex>
  );
}
