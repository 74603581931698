import React, { useState } from 'react';
import {
  Flex,
  Grid,
  Box,
  Text,
  VStack,
  Center,
  Button,
  Heading,
  Icon,
  useToast,
} from "@chakra-ui/react";
import { useNavigate } from 'react-router-dom';
import { FaBook, FaRobot, FaPen, FaUpload } from 'react-icons/fa';
import axios from 'axios';
import apiClient from "../../../api/axiosInstance"; // 생성한 Axios 인스턴스를 import


export default function CreateSolutionDashboard() {
  const [file, setFile] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();

  const handleSelect = () => {
    navigate('/select-passage');
  };

  const handleWritePassage = () => {
    navigate('/write-passage');
  };

  const handleAIPassage = () => {
    navigate('/passage-ai-create');
  };

  const handleOCRPassage = () => {
    navigate('/modify-problem');
  };

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    let form_data = new FormData();
    form_data.append('image', file);
    form_data.append('title', file.name);
    let url = `/solution_generator/problem/`;
    apiClient.post(url, form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
      .then(res => {
        console.log(res.data);
        navigate('/create-solution-result', {
          state: { problem_id: res.data.id, html_text: res.data.html_text }
        });
      })
      .catch(err => console.log(err));
  };

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <br />
      <Grid
        templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }}
        templateRows={{ base: "repeat(4, auto)", md: "repeat(2, 1fr)" }}
        gap={6}
        mx="auto"
        maxW="900px"
      >
        <Box bg="teal.50" borderWidth="1px" borderRadius="lg" p={5} boxShadow="md" textAlign="center">
          <Icon as={FaBook} w={10} h={10} color="teal.600" mb={4} />
          <Heading size="md" mb={2}>지문 은행</Heading>
          <Text mb={4}>기존의 지문을 선택하여 문제를 생성하세요.</Text>
          <Button colorScheme="teal" onClick={handleSelect}>선택</Button>
        </Box>
        <Box bg="green.50" borderWidth="1px" borderRadius="lg" p={5} boxShadow="md" textAlign="center">
          <Icon as={FaRobot} w={10} h={10} color="green.600" mb={4} />
          <Heading size="md" mb={2}>AI 지문 생성</Heading>
          <Text mb={4}>AI를 사용하여 새로운 지문을 생성하세요.</Text>
          <Button colorScheme="green" onClick={handleAIPassage}>생성</Button>
        </Box>
        <Box bg="cyan.50" borderWidth="1px" borderRadius="lg" p={5} boxShadow="md" textAlign="center">
          <Icon as={FaPen} w={10} h={10} color="cyan.600" mb={4} />
          <Heading size="md" mb={2}>직접 지문 입력</Heading>
          <Text mb={4}>새로운 지문을 직접 입력하세요.</Text>
          <Button colorScheme="cyan" onClick={handleWritePassage}>입력</Button>
        </Box>
        <Box bg="orange.50" borderWidth="1px" borderRadius="lg" p={5} boxShadow="md" textAlign="center">
          <Icon as={FaUpload} w={10} h={10} color="orange.600" mb={4} />
          <Heading size="md" mb={2}>지문 이미지 업로드</Heading>
          <Text mb={4}>이미지를 업로드하여 지문을 생성하세요.</Text>
          <Button colorScheme="orange" onClick={handleOCRPassage}>업로드</Button>
        </Box>
      </Grid>
      <br />
    </Flex>
  );
}
