// Chakra imports
import {
    Flex,
    Grid,
    Image,
    SimpleGrid,
    useColorModeValue,
    Box,
    Text,
    VStack,
    Center
  } from "@chakra-ui/react";
  
  import { Card, CardHeader, CardBody } from '@chakra-ui/react'
  import { Heading } from '@chakra-ui/react'
  import { useState } from "react";
  import axios from 'axios';
  import apiClient from "../../../api/axiosInstance"; // 생성한 Axios 인스턴스를 import

  import { Button, ButtonGroup } from '@chakra-ui/react'
  import { useNavigate } from 'react-router-dom';
  import Feather from "../../static/img/feather-upload-cloud-1.svg";
  
  import logoChakra from "assets/svg/logo-white.svg";
  import React from "react";
  import { FileUploader } from "react-drag-drop-files";
  import FileUploadCard from "./components/FileUploadCard";
  import UploadFileListCard from "./components/UploadFileListCard";
  import {uploadFileSampleData} from "../../../variables/uploadfilesampledata";
  
  const fileTypes = ["JPEG", "PNG", "GIF"];
  
  
  export default function ModifySolutionDashboard() {
    const [file, setFile] = useState(null);
    const navigate = useNavigate();
    const [isloading, setLoading] = useState(false);

  
    const UploadField =  (

      <Box w='100%' borderWidth='1px' 
        borderRadius='lg'
        overflow='hidden' 
        align='center' 
        paddingInline='40' 
        paddingBlock='5'>
      <VStack spacing={3}>
      <img
        className="feather-upload-cloud"
        alt="Feather upload cloud"
        src={Feather}
      />
        <Text fontSize='md'>파일을 선택하거나 여기에 끌어서 놓아주세요.</Text>
        <Text fontSize='xs'  color='gray'>10MB 이하의 JPG, PNG 파일만 업로드 가능 합니다.</Text>
        <Button>
         파일 선택</Button></VStack>
      </Box>
  );
  const handleChange = (file) => {
    setFile(file);
  };
  
  const handleSubmit = (e) => {
    setLoading((prevLoading) => { return true})
    e.preventDefault();
    console.log(file);
    let form_data = new FormData();
    form_data.append('image', file);
    form_data.append('title', file.name);
    let url = '/pdf_extractor/google-ocr/';
    apiClient.post(url, form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
        .then(res => {
          console.log(res.data);
          navigate('/write-passage', 
          { state: {description: res.data.description, title: file.name} }
          );
        })
        .catch(err => console.log(err))
  };
  
    return (
      <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
  
  
        <Grid
          templateColumns={{ md: "1fr", lg: "1fr" }}
          templateRows={{ md: "1fr auto", lg: "1fr" }}
          my="26px"
          gap="24px"
        >
  
  <Box bg="white" borderWidth="1px" borderRadius="lg" p={5}>
          <Box p={3}>
            <Center>
      <Heading size='md'> 문제 업로드 </Heading>
      </Center>
    </Box>
    <form onSubmit={handleSubmit}>
          <FileUploader
          children={UploadField}
          multiple={false}
          handleChange={handleChange}
          name="file"
          types={fileTypes}
        />
  {file ?
         <UploadFileListCard
            title={"업로드 된 파일"}
            captions={["Name", "Size", "File Type"]}
            data={file}
          /> :""}
    {!isloading?
          <Button
              mt={4}
              colorScheme='teal'
              // isLoading={props.isSubmitting}
              type='submit'
            >
지문 업로드            </Button>:
        <Button mt={4} isLoading colorScheme='teal' variant='solid'>
지문 업로드            
  </Button>}
        </form>
        </Box>
        </Grid>
      </Flex>
    );
  }
  