import { Flex, Box, Center, IconButton } from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import React, { useEffect, useState } from "react";
import QuestionEditorComponent from "./QuestionEditorComponent";

export default function QuestionEditor(props) {

  const handleDataAdd = (data) => {
    const newQuestionComponents = [...props.questionComponents];
    newQuestionComponents[data.id] = {
      ...newQuestionComponents[data.id],
      ...Object.fromEntries(Object.entries(data).map(([key, value]) => [key, value]))
    };
    props.setQuestionComponents(newQuestionComponents);
  };


  const handleComponentCategoryChange = (data) => {
    const newQuestionComponents = [...props.questionComponents];
    newQuestionComponents[data.id] = {
      ...Object.fromEntries(Object.entries(data).map(([key, value]) => [key, value]))
    };
    props.setQuestionComponents(newQuestionComponents);
  }


  const handleBlur = (data) => {
    const newQuestionComponents = [...props.questionComponents];
    newQuestionComponents[data.id] = {
      ...newQuestionComponents[data.id],
      ...Object.fromEntries(Object.entries(data).map(([key, value]) => [key, value]))
    };
    props.setQuestionComponents(newQuestionComponents);
  };

  const addQuestionComponent = () => {
    props.setQuestionComponents([
      ...props.questionComponents,
      { id: props.questionComponents.length, category: props.componentCategories[0] },
    ]);
  };

  const removeQuestionComponent = (id) => {
    // const newQuestionData = [...questionData];
    // newQuestionData.splice(id, 1);
    // // 각 컴포넌트 아이디 재할당
    // newQuestionData.forEach((data, i) => {
    //   data.id = i;
    // });
    // setQuestionData(newQuestionData);
  };




  
  return (
    <Box bg="beige" borderWidth="1px" borderRadius="lg" p={3} mb={6}>
      <Flex flexDirection="column">
        {props.questionComponents.map((data, i) => (
          <QuestionEditorComponent
            key={i}
            id={i}
            category={data.category}
            componentCategories={props.componentCategories}
            handleDataAdd={handleDataAdd}
            handleBlur={handleBlur}
            handleRemove={removeQuestionComponent}
            handleComponentCategoryChange={handleComponentCategoryChange}
          />
        ))}
      </Flex>
      <Center marginTop={4}>
        <IconButton
          aria-label="Add question"
          icon={<AddIcon />}
          onClick={addQuestionComponent}
        />
      </Center>
    </Box>
  );
}
