import { Flex, Box, Heading, Grid, Icon, HStack } from "@chakra-ui/react";
import { WarningIcon } from "@chakra-ui/icons"; // Import an icon from Chakra UI

export default function ErrorDashboard({ errorMessage }) {
  return (
    <Flex
      flexDirection="column"
      pt={{ base: "120px", md: "75px" }}
      px={{ base: 4, md: 8 }}
    >
      <Grid
        templateColumns={{ md: "1fr", lg: "1fr" }}
        templateRows={{ md: "1fr auto", lg: "1fr" }}
        my="26px"
        gap="24px"
      >
        <Box textAlign="center">
          <HStack spacing={2} justify="center">
            <Icon as={WarningIcon} w={6} h={6} color="red.500" />
            <Heading size="md" color="red.500">
              {errorMessage}
            </Heading>
          </HStack>
        </Box>
      </Grid>
    </Flex>
  );
}
