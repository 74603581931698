// import
import Dashboard from "views/Dashboard/Dashboard";
import CreateSolutionDashBoard from "./views/Dashboard/Dashboard/CreateSolutionDashboard.js";
import ModifySolutionDashboard from "./views/Dashboard/Dashboard/ModifySolutionDashboard.js";
import ProblemBankDashboard from "./views/Dashboard/Dashboard/ProblemBankDashboard.js";
import CreatePassageAIDashboard from "./views/Dashboard/Dashboard/CreatePassageAIDashboard.js";
import CreateLiteratureDashboard from "./views/Dashboard/Dashboard/CreateLiteratureDashboard.js";
import Tables from "views/Dashboard/Tables";
import Billing from "views/Dashboard/Billing";
import Profile from "views/Dashboard/Profile";
import SignIn from "views/Auth/SignIn.js";
import SignUp from "views/Auth/SignUp.js";

import {
  FaBook,
  FaRobot,
  FaPen,
  FaUpload,
  FaDatabase,
  FaBookOpen,
} from "react-icons/fa";

import {
  HomeIcon,
  StatsIcon,
  CreditIcon,
  PersonIcon,
  DocumentIcon,
  RocketIcon,
  SupportIcon,
} from "./components/Icons/Icons.js";
import { TfiAgenda } from "react-icons/tfi";
import ExtractLiteratureDashboard from "./views/Dashboard/Dashboard/PoemExtractLiteratureDashboard.js";

var dashRoutes = [
  {
    path: "/create-solution",
    name: "지문 은행",
    icon: <HomeIcon color="inherit" />,
    element: <CreateSolutionDashBoard />,
    layout: "",
  },
  // {
  //   path: "/modify-problem",
  //   name: "문제 변형",
  //   icon: <StatsIcon color="inherit" />,
  //   element: <ModifySolutionDashboard/>,
  //   layout: "",
  // },

  {
    path: "/passage-literature-create/",
    name: "문학 지문 생성",
    icon: <TfiAgenda color="inherit" />,
    layout: "",
    element: <CreateLiteratureDashboard />,
    layout: "",
  },

  // {
  //   path: "/passage-literature-extract/",
  //  name: "문학 지문 생성",
  // icon: <TfiAgenda color="inherit" />,
  // layout: "",
  // element: <ExtractLiteratureDashboard />,
  // layout: "",
  // },

  {
    path: "/passage-bmoon-create/",
    name: "비문학 지문 생성",
    icon: <DocumentIcon color="inherit" />,
    element: <CreatePassageAIDashboard />,
    layout: "",
  },

  // {
  //   path: "/write-passage",
  //   name: "직접 지문 입력",
  //   icon: <FaPen color="inherit" />,
  //   element: <ProblemBankDashboard />,
  //   layout: "",
  // },

  // {
  //   path: "/modify-problem",
  //   name: "지문 이미지 업로드",
  //   icon: <FaUpload color="inherit" />,
  //   element: <ProblemBankDashboard />,
  //   layout: "",
  // },

  {
    path: "/problem-bank",
    name: "나의 문제 은행",
    icon: <FaDatabase color="inherit" />,
    element: <ProblemBankDashboard />,
    layout: "",
  },

  // {
  //   path: "/workbook-list",
  //   name: "만든 문제집",
  //   icon: <FaBookOpen color="inherit" />,
  //   element: <ProblemBankDashboard />,
  //   layout: "",
  // },

  // {
  //   path: "/billing",
  //   name: "Pricing",
  //   icon: <CreditIcon color="inherit" />,
  //   element: Billing,
  //   layout: "/admin",
  // },
  // {
  //   name: "ACCOUNT PAGES",
  //   category: "account",
  //   state: "pageCollapse",
  //   views: [
  //     {
  //       path: "/profile",
  //       name: "Profile",
  //       icon: <PersonIcon color="inherit" />,
  //       secondaryNavbar: true,
  //       element: Profile,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/signin",
  //       name: "Sign In",
  //       icon: <DocumentIcon color="inherit" />,
  //       element: SignIn,
  //       layout: "/auth",
  //     },
  //     {
  //       path: "/signup",
  //       name: "Sign Up",
  //       icon: <RocketIcon color="inherit" />,
  //       secondaryNavbar: true,
  //       element: SignUp,
  //       layout: "/auth",
  //     },
  //   ],
  // },
];
export default dashRoutes;
