import React, { useState, useEffect } from 'react';
import {
  Flex,
  Grid,
  Box,
  Heading,
  Text,
  Input,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  HStack,
  useToast,
  Stack,
  Textarea,
  Tag,
  Button,
  Skeleton,
  SkeletonText,
  Wrap,
  WrapItem,
  IconButton,
  List,
  ListItem,
} from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon, ArrowLeftIcon, ArrowRightIcon } from "@chakra-ui/icons";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export default function ProblemBankDashboard() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [problems, setProblems] = useState([]);
  const [passages, setPassages] = useState([]);
  const [category, setCategory] = useState("");
  const [filteredProblems, setFilteredProblems] = useState({});
  const [nextPageUrl, setNextPageUrl] = useState(null);
  const [prevPageUrl, setPrevPageUrl] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 5;
  const maxPageButtons = 3;
  const toast = useToast();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");


  const categoryColors = {
    "전체": "teal",
    "문학 작품 - 현대 시": "red",
    "문학 작품 - 현대 소설": "orange",
    "독서 이론": "green",
    "사회(법)": "purple",
    "사회(경제)": "yellow",
    "사회(일반)": "blue",
    "인문": "cyan",
    "예술(일반)": "pink",
    "예술(건축)": "gray",
    "기술": "lime",
    "과학(생물)": "green",
    "과학(화학)": "orange",
    "과학(물리)": "blue",
    "과학(천체물리)": "indigo",
  };

  const categories = ["전체", "문학 작품 - 현대 시", "문학 작품 - 현대 소설", "독서 이론", "사회(법)", "사회(경제)", "사회(일반)", "인문", "예술(일반)", "예술(건축)", "기술", "과학(생물)", "과학(화학)", "과학(물리)", "과학(천체물리)"];

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_API_ENDPOINT}/problem_generator/all_filtered_questions/`,
        {
          params: { searchTerm, selectedCategory: category === "전체" ? "" : category },
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const fetchedData = Array.isArray(response.data) ? response.data : response.data.results;
      setPassages(fetchedData || []);
    } catch (error) {
      console.error("Error fetching filtered data:", error);
    } finally {
      setIsLoading(false);
    }
  };
  
  const extractTextFromJson = (jsonString) => {
    try {
      // ```json 및 ``` 구문 제거
      const cleanedJsonString = jsonString
        .replace(/^```json/, "")
        .replace(/```$/, "")
        .trim();
      
      // 문자열을 JSON 객체로 변환
      const jsonObject = JSON.parse(cleanedJsonString);
      return extractTextRecursively(jsonObject);
    } catch (error) {
      // console.error("Invalid JSON string:", error);
      return jsonString; // JSON 변환 실패 시 원본 문자열 반환
    }
  };

  // JSON 객체에서 재귀적으로 텍스트만 추출하는 함수
  const extractTextRecursively = (json) => {
    let text = "";
    if (typeof json === "string") {
      return json;
    } else if (typeof json === "object" && json !== null) {
      for (const key in json) {
        text += extractTextRecursively(json[key]) + "\n\n";
      }
    }
    return text.trim();
  };

  useEffect(() => {
    // category나 searchTerm이 변경될 때 fetchData 호출
    fetchData();
  }, [category, searchTerm]);
  
  const handleSelect = (type) => {
    setCategory(type === "전체" ? "" : type);
    setCurrentPage(1); // 페이지를 초기화
  };
  
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
    fetchData(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleCreateQuestion = (passage) => {
    navigate('/create-question', {
      state: {
        passage_id: passage.id,
        title: passage.title,
        contents: passage.contents,
        category: passage.category,
      }
    });
  };

  const Pagination = ({ totalItems, currentPage, onPageChange }) => {
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const getPageNumbers = () => {
      let startPage = Math.max(1, currentPage - Math.floor(maxPageButtons / 2));
      let endPage = startPage + maxPageButtons - 1;
      if (endPage > totalPages) {
        endPage = totalPages;
        startPage = Math.max(1, endPage - maxPageButtons + 1);
      }
      return Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
    };

    return (
      <Flex justify="center" mt={4} gap={2} align="center">
        <Button size="sm" onClick={() => onPageChange(1)} isDisabled={currentPage === 1} colorScheme="teal" variant="ghost">
          <ArrowLeftIcon />
        </Button>
        <Button size="sm" onClick={() => onPageChange(currentPage - 1)} isDisabled={currentPage === 1} colorScheme="teal" variant="ghost">
          <ChevronLeftIcon boxSize={4} />
        </Button>
        {currentPage > maxPageButtons && <Text fontSize="sm" color="gray.500">...</Text>}
        {getPageNumbers().map((pageNum) => (
          <Button
            key={pageNum}
            size="sm"
            onClick={() => onPageChange(pageNum)}
            colorScheme="teal"
            variant={currentPage === pageNum ? "solid" : "outline"}
            minW="32px"
          >
            {pageNum}
          </Button>
        ))}
        {currentPage < totalPages - maxPageButtons + 1 && <Text fontSize="sm" color="gray.500">...</Text>}
        <Button size="sm" onClick={() => onPageChange(currentPage + 1)} isDisabled={currentPage === totalPages} colorScheme="teal" variant="ghost">
          <ChevronRightIcon boxSize={4} />
        </Button>
        <Button size="sm" onClick={() => onPageChange(totalPages)} isDisabled={currentPage === totalPages} colorScheme="teal" variant="ghost">
          <ArrowRightIcon />
        </Button>
      </Flex>
    );
  };

  return (
    <Flex
      flexDirection="column"
      pt={{ base: "120px", md: "75px" }}
      px={{ base: 4, md: 8 }}
    >
      <Grid
        templateColumns={{ md: "1fr", lg: "1fr" }}
        templateRows={{ md: "1fr auto", lg: "1fr" }}
        my="26px"
        gap="24px"
      >
        <Box mb={8}>
          <Heading size="lg" color="teal.600">
            문제 생성
          </Heading>
          <Text color="gray.600" mt={2}>
            원하시는 지문을 선택하세요
          </Text>
        </Box>
        <Box bg="white" borderWidth="1px" borderRadius="xl" p={5} mb={6} boxShadow="md">
          <Input
            borderRadius="full"
            placeholder="검색어를 입력하세요"
            value={searchTerm}
            onChange={handleSearch}
            mt={4}
            mb={4}
          />
          
          <Wrap spacing={4} justify="center">
            {categories.map((cat) => (
              <WrapItem key={cat}>
                <Button
                  colorScheme={categoryColors[cat]}
                  variant={category === cat ? 'solid' : 'outline'}
                  onClick={() => handleSelect(cat)}
                  borderRadius="full"
                  boxShadow="sm"
                  _hover={{ boxShadow: "md" }}
                  _focus={{ boxShadow: "outline" }}
                >
                  {cat}
                </Button>
              </WrapItem>
            ))}
          </Wrap>

          <br />
          <Accordion allowToggle>
            {isLoading | passages.length == 0 ? (
              <Stack spacing={4}>
                {[1, 2, 3].map((_, index) => (
                  <Box key={index} padding="6" boxShadow="lg" bg="white" borderRadius="xl">
                    <Skeleton height="20px" />
                    <SkeletonText mt="4" noOfLines={4} spacing="4" />
                    <Skeleton height="20px" mt="4" />
                  </Box>
                ))}
              </Stack>
            ) : (
              passages
              .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
              .map((passage, index) => (
                <AccordionItem key={index} borderRadius="xl" border="1px solid" borderColor="gray.200" mb={3}>
                  <h2>
                    <AccordionButton borderRadius="xl">
                      <Box flex="1" textAlign="left">
                        {passage.title}
                      </Box>
                      <Tag borderRadius='full' colorScheme={categoryColors[passage.category] || 'teal'}>
                        {passage.category}
                      </Tag>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Box>
                        <Text whiteSpace="pre-wrap">
                        {extractTextFromJson(passage.contents)}
                      </Text>
                    </Box>
                    {passage.questions && passage.questions.length > 0 && (
                      <Box mt={4}>
                      <List spacing={3} mt={4}>
                        {passage.questions.map((question, qIndex) => (
                           <ListItem
                           key={question.id}
                           borderWidth="1px"
                           borderRadius="xl"
                           p={3}
                           boxShadow="md"
                           _hover={{ boxShadow: "lg", bg: "gray.50" }}
                           transition="all 0.3s ease"
                         >
                           {/* <Checkbox
                             colorScheme="teal"
                             onChange={(event) => checkHandler(question, event)}
                             mr={4}
                           /> */}
                           <Box flex="1" textAlign="left">
                             <Text fontSize="md" fontWeight="bold" color="gray.800">
                               {question.question}
                             </Text>
                             {question.supplement && (
                               <Box bg="white" borderWidth="1px" borderRadius="lg" p={3} whiteSpace="pre-wrap" mt={3}>
                                 {question.supplement}
                               </Box>
                             )}
                             <Text mt={2} color="gray.600">1. {question.choice_1}</Text>
                             <Text color="gray.600">2. {question.choice_2}</Text>
                             <Text color="gray.600">3. {question.choice_3}</Text>
                             <Text color="gray.600">4. {question.choice_4}</Text>
                             <Text color="gray.600">5. {question.choice_5}</Text>
                           </Box>
                         </ListItem>
                        ))}
                       </List> 
                      </Box>
                    )}
                    <Box mt="2">
                        <HStack spacing={2}>
                          <Button
                            colorScheme="teal"
                            size="sm"
                            borderRadius="full"
                            onClick={() => handleCreateQuestion(passage)}
                          >
                            문제 생성하기
                          </Button>
                          
                        </HStack>
                    </Box>
                  </AccordionPanel>
                </AccordionItem>
              ))
            )}
          </Accordion>
          <Pagination totalItems={passages.length} currentPage={currentPage} onPageChange={handlePageChange} />
        </Box>
      </Grid>
    </Flex>
  );
}
