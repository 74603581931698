import {
  Flex,
  Grid,
  Box,
  Center,
  Button,
  Heading,
  useToast,
  Input,
  Select,
  Textarea,
  FormControl,
  FormLabel,
  HStack,
  VStack,
  IconButton,
  Text,
} from "@chakra-ui/react";
import { AddIcon, CloseIcon } from "@chakra-ui/icons";
import React, { useCallback, useState, useEffect } from "react";

export default function QuestionChoices(props) {
  const [choices, setChoices] = useState([
    { text: "" },
    { text: "" },
    { text: "" },
    { text: "" },
    { text: "" },
  ]);

useEffect(()=>{
  props.handleDataAdd({id: props.id, choices: choices});
},[]);
  
  return (
    <FormControl id="question-choices">
      <VStack spacing={2} align="start" padding={3}>
        {choices.map((choice, index) => (
          <HStack key={index}>
            <Text width="15%">{index + 1}번</Text>
            <FormControl>
              <Input
                // height="150%"
                height="30px"
                value={choice.text} // Add this line
                onChange={(e) => {
                  const newChoices = [...choices];
                  newChoices[index].text = e.target.value;
                  setChoices(newChoices);
                }}
                onBlur={(e) => {
                  const newChoices = [...choices];
                  newChoices[index].text = e.target.value;
                  setChoices(newChoices);
                  props.handleDataAdd({id: props.id, choices: newChoices});
                }}
                placeholder={`선택지를 입력하세요`}
              />
            </FormControl>
            <IconButton
              size="sm"
              aria-label="Remove choice"
              icon={<CloseIcon />}
              onClick={() => {
                // 최소 1개 이상 선택지 필요
                if (choices.length === 1) {
                  return;
                }
                const newChoices = [...choices];
                newChoices.splice(index, 1);
                setChoices(newChoices);
                props.handleDataAdd({id: props.id, choices: newChoices});
              }}
            />
          </HStack>
        ))}
        {choices.length < 5 && (
          <Button
            leftIcon={<AddIcon />}
            onClick={() => {
              const newChoices = [...choices];
              newChoices.push({ text: "" });
              setChoices(newChoices);
              props.handleDataAdd({id: props.id, choices: newChoices});

            }}
          >
            선택지 추가
          </Button>
        )}
      </VStack>
    </FormControl>
  );
}
