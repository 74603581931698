// src/api/axiosInstance.js
import axios from 'axios';

// Axios 인스턴스 생성
const apiClient = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API_ENDPOINT, // 환경 변수에서 기본 URL 가져오기
  headers: {
    'Content-Type': 'application/json',
  },
});

// 요청 인터셉터: Authorization 헤더에 토큰 추가
apiClient.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("token");
      //console.log('Interceptor Token:', token); // 토큰 값 출력
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      //console.log('Config Headers:', config.headers); // 헤더 확인
      return config;
    },
    (error) => Promise.reject(error)
  );
  

// 응답 인터셉터: 401 에러 처리 및 토큰 갱신 로직
apiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response && error.response.status === 401) {
      const refreshToken = localStorage.getItem("refresh");
      if (!refreshToken) {
        window.location.href = '/signin';
        return Promise.reject(error);
      }

      try {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/refresh/`, {
          refreshToken,
        });

        const newAccessToken = response.data.accessToken;
        localStorage.setItem("token", newAccessToken);

        originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
        return apiClient(originalRequest); // 실패했던 요청 재시도
      } catch (tokenRefreshError) {
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        window.location.href = '/login';
        return Promise.reject(tokenRefreshError);
      }
    }

    return Promise.reject(error);
  }
);

export default apiClient;
