import {
  Flex,
  Grid,
  Box,
  Center,
  Button,
  Heading,
  useToast,
  Input,
  Select,
  Textarea,
  FormControl,
  FormLabel,
  HStack,
  VStack,
  IconButton,
} from "@chakra-ui/react";
import { AddIcon, CloseIcon } from "@chakra-ui/icons";
import React, { useCallback, useState, useEffect } from "react";

export default function QuestionBoxContent(props) {
  // const [boxContent, setBoxContent] = useState("");

  return (
    <FormControl id="question-content-box">
      <Textarea
        height="200px"
        placeholder="박스 안 내용을 입력하세요"
        onBlur={(e) => {
          props.handleDataAdd({id:props.id, statement: e.target.value});
        }}
      />
    </FormControl>
  );
}
