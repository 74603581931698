import {
  Flex,
  Grid,
  Text,
  Checkbox,
  Button,
  Stack,
  Box,
  Skeleton,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Heading,
  Center,
  List,
  ListItem,
  Input,
  Textarea,
  useToast,
  Wrap,
  WrapItem,
  HStack,
  useColorModeValue,
} from "@chakra-ui/react";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  FormControl,
  FormLabel,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from "@chakra-ui/react";
import { StarIcon } from "@chakra-ui/icons";

import { useState, useEffect } from "react";
import axios from "axios";
import apiClient from "../../../api/axiosInstance"; // 생성한 Axios 인스턴스를 import

import { useNavigate } from "react-router-dom";
import React from "react";

import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
} from "@chakra-ui/icons";

export default function ProblemBankDashboard() {
  const [problems, setProblems] = useState([]);
  const [passages, setPassages] = useState({});
  const [checkedItems, setCheckedItems] = useState(new Set());
  const [isLoading, setIsLoading] = useState(false);
  const [workbookTitle, setWorkbookTitle] = useState("");
  const [workbookDescription, setWorkbookDescription] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const toast = useToast();
  const navigate = useNavigate();
  const maxPageButtons = 3; // 한 번에 보여줄 최대 페이지 버튼 수를 3개로 변경
  const [filteredProblems, setFilteredProblems] = useState([]);


    // 상단에 state 추가
const [currentPage, setCurrentPage] = useState(1);
const itemsPerPage = 5; // 페이지당 보여줄 아이템 수
const inputBg = useColorModeValue("gray.50", "gray.800");
const bgColor = useColorModeValue("white", "gray.700");
const textColor = useColorModeValue("gray.600", "gray.300");
  const categories = ["전체", "문학 작품 - 현대 시", "문학 작품 - 현대 소설" ,"독서 이론", "사회(법)", "사회(경제)", "사회(일반)", "인문", "예술(일반)", "예술(건축)", "기술", "과학(생물)", "과학(화학)", "과학(물리)", "과학(천체물리)"];

  function checkedItemHandler(e, isChecked) {
    setCheckedItems((prevCheckedItems) => {
      const newCheckedItems = new Set(prevCheckedItems);
      if (isChecked) {
        newCheckedItems.add(e);
      } else {
        newCheckedItems.delete(e);
      }
      return newCheckedItems;
    });
  }

  const checkHandler = (e, event) => {
    checkedItemHandler(e, event.target.checked);
  };

  const createWorkbook = () => {
    if (!workbookTitle.trim() || !workbookDescription.trim()) {
      toast({
        title: "입력 오류",
        description: "문제집 제목과 설명을 모두 입력해 주세요.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setIsLoading(true);
    const selectedQuestions = Array.from(checkedItems);
    const workbookData = {
      title: workbookTitle,
      description: workbookDescription,
      question_ids: selectedQuestions.map((question) => question.id),
    };

    apiClient
      .post(
        `/problem_generator/workbooks/`,
        workbookData
      )
      .then((response) => {
        const workbook = response.data;
        navigate("/problem-bank-result", { state: { workbook, passages } });
      })
      .catch((error) => {
        console.error("Error creating workbook:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };


  useEffect(() => {
    const token = localStorage.getItem("token");

    const fetchFilteredData = async () => {
      setIsLoading(true);
      try {
        const response = await apiClient.get(
          `/problem_generator/filtered_questions/`,
          {
            params: {
              searchTerm,
              selectedCategory,
            }
          }
        );
        setFilteredProblems(response.data); // Passages와 연결된 Questions 배열
      } catch (error) {
        console.error("Error fetching filtered data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchFilteredData();
  }, [searchTerm, selectedCategory]);


  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm, selectedCategory]);


  const PassageRating = ({ passage, onUpdate }) => {
    const [rating, setRating] = useState(passage.rating || 0);
    const [hoveredRating, setHoveredRating] = useState(0);
    const [feedback, setFeedback] = useState(passage.feedback || "");
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = async () => {
      setIsSubmitting(true);
      try {
        const token = localStorage.getItem("token");
        const response = await apiClient.put(
          `/problem_generator/passages/${passage.id}`,
          {
            rating,
            feedback
          }
        );
        
        if (onUpdate) {
          onUpdate(response.data);
        }
        
        toast({
          title: "평가가 업데이트되었습니다.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        
        onClose();
      } catch (error) {
        toast({
          title: "오류가 발생했습니다.",
          description: error.response?.data?.message || "다시 시도해주세요.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setIsSubmitting(false);
      }
    };


    // PassageRating 컴포넌트 수정
  // 별점 렌더링 컴포넌트
  const StarRatingIcon = ({ fill }) => {
    // fill은 0부터 1 사이의 값
    if (fill === 0) {
      return <StarIcon color="gray.200" />;
    }
    
    return (
      <Box position="relative" display="inline-block">
        <Box position="absolute" overflow="hidden" width={`${fill * 100}%`}>
          <StarIcon color="yellow.400" />
        </Box>
        <StarIcon color="gray.200" />
      </Box>
    );
  };

  const StarRating = () => {
    const handleMouseMove = (e, index) => {
      const box = e.currentTarget.getBoundingClientRect();
      const starWidth = box.width;
      const offsetX = e.clientX - box.left;
      const percentage = offsetX / starWidth;
      
      if (percentage <= 0.5) {
        setHoveredRating(index + 0.5);
      } else {
        setHoveredRating(index + 1);
      }
    };

    return (
      <Flex>
        {[0, 1, 2, 3, 4].map((index) => (
          <Box
            key={index}
            cursor="pointer"
            w={8}
            h={8}
            m={1}
            position="relative"
            onClick={() => setRating(hoveredRating)}
            onMouseMove={(e) => handleMouseMove(e, index)}
            onMouseLeave={() => setHoveredRating(0)}
          >
            <StarRatingIcon
              fill={Math.max(0, Math.min(1, ((hoveredRating || rating) - index)))}
            />
          </Box>
        ))}
      </Flex>
    );
  };

  return (
    <>
      <Button
        leftIcon={<StarIcon />}
        size="sm"
        variant="outline"
        colorScheme="yellow"
        onClick={onOpen}
        ml={2}
      >
        {rating ? `${rating.toFixed(1)}점` : '평가하기'}
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>지문 평가</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl mb={4}>
              <FormLabel>평점</FormLabel>
              <Center mb={4}>
                <StarRating />
              </Center>
            </FormControl>
            
            <FormControl>
              <FormLabel>피드백</FormLabel>
              <Textarea
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                placeholder="지문에 대한 피드백을 입력해주세요"
                resize="vertical"
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="teal"
              mr={3}
              onClick={handleSubmit}
              isLoading={isSubmitting}
              isDisabled={!rating}
            >
              저장
            </Button>
            <Button variant="ghost" onClick={onClose}>취소</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

  


// Pagination 컴포넌트 추가
const Pagination = ({ totalItems, currentPage, onPageChange }) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  
  const getPageNumbers = () => {
    let startPage = Math.max(1, currentPage - Math.floor(maxPageButtons / 2));
    let endPage = startPage + maxPageButtons - 1;
    
    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(1, endPage - maxPageButtons + 1);
    }
    
    return Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
  };

  return (
    <Flex justify="center" mt={4} gap={2} align="center">
      <Button
        size="sm"
        onClick={() => onPageChange(1)}
        isDisabled={currentPage === 1}
        colorScheme="teal"
        variant="ghost"
        aria-label="첫 페이지"
      >
        <ArrowLeftIcon />
      </Button>
      <Button
        size="sm"
        onClick={() => onPageChange(currentPage - 1)}
        isDisabled={currentPage === 1}
        colorScheme="teal"
        variant="ghost"
        aria-label="이전 페이지"
      >
        <ChevronLeftIcon boxSize={4} />
      </Button>
      
      {currentPage > maxPageButtons && (
        <>
          <Text fontSize="sm" color="gray.500">...</Text>
        </>
      )}
      
      {getPageNumbers().map(pageNum => (
        <Button
          key={pageNum}
          size="sm"
          onClick={() => onPageChange(pageNum)}
          colorScheme="teal"
          variant={currentPage === pageNum ? "solid" : "outline"}
          minW="32px"
        >
          {pageNum}
        </Button>
      ))}
      
      {currentPage < totalPages - maxPageButtons + 1 && (
        <>
          <Text fontSize="sm" color="gray.500">...</Text>
        </>
      )}
      
      <Button
        size="sm"
        onClick={() => onPageChange(currentPage + 1)}
        isDisabled={currentPage === totalPages}
        colorScheme="teal"
        variant="ghost"
        aria-label="다음 페이지"
      >
        <ChevronRightIcon boxSize={4} />
      </Button>
      <Button
        size="sm"
        onClick={() => onPageChange(totalPages)}
        isDisabled={currentPage === totalPages}
        colorScheme="teal"
        variant="ghost"
        aria-label="마지막 페이지"
      >
        <ArrowRightIcon />
      </Button>
    </Flex>
  );
};


  return (
    <Flex
    flexDirection="column"
    pt={{ base: "120px", md: "75px" }}
    px={{ base: 4, md: 8 }}
  >
    <Grid
      templateColumns={{ md: "1fr", lg: "1fr" }}
      templateRows={{ md: "1fr auto", lg: "1fr" }}
      my="26px"
      gap="24px"
    >
      <Box mb={8}>
        <Heading size="lg" color="teal.600">
        문제 은행
        </Heading>
        <Text color="gray.600" mt={2}>
          문제를 평가해주세요.
        </Text>
      </Box>
      <Box bg="white" borderWidth="1px" borderRadius="xl" p={5} mb={6} boxShadow="md">
          {/* <Box mb={6}>
            <Input
              placeholder="문제집 제목을 입력하세요"
              value={workbookTitle}
              onChange={(e) => setWorkbookTitle(e.target.value)}
              mb={4}
              borderRadius="full"
              borderColor="teal.500"
              _hover={{ borderColor: "teal.400" }}
              _focus={{
                borderColor: "teal.600",
                boxShadow: "0 0 0 2px rgba(56, 178, 172, 0.6)",
              }}
            />
            <Textarea
              placeholder="문제집 설명을 입력하세요"
              value={workbookDescription}
              onChange={(e) => setWorkbookDescription(e.target.value)}
              resize="vertical"
              borderRadius="xl"
              _hover={{ borderColor: "teal.400" }}
              _focus={{
                borderColor: "teal.600",
                boxShadow: "0 0 0 2px rgba(56, 178, 172, 0.6)",
              }}
            />
          </Box> */}

          <Box mb={4}>
            <Heading size="md" color="teal.600">
              지문
            </Heading>
          </Box>

          <Input
            placeholder="검색어를 입력하세요"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            mb={4}
            borderRadius="full"
            _hover={{ borderColor: "teal.400" }}
            _focus={{
              borderColor: "teal.600",
              boxShadow: "0 0 0 2px rgba(56, 178, 172, 0.6)",
            }}
          />

          <Wrap spacing={4} justify="center" mb={4}>
            {categories.map((cat) => (
              <WrapItem key={cat}>
                <Button
                  colorScheme={cat === selectedCategory ? "teal" : "gray"}
                  variant={cat === selectedCategory ? "solid" : "outline"}
                  onClick={() => setSelectedCategory(cat)}
                  _hover={{ bg: cat === selectedCategory ? "teal.400" : "gray.200" }}
                  borderRadius="full"
                >
                  {cat}
                </Button>
              </WrapItem>
            ))}
          </Wrap>
          <Accordion allowMultiple borderRadius="xl">
          {isLoading ? (
    // 로딩 중일 때 Skeleton 컴포넌트 표시
    Array.from({ length: itemsPerPage }).map((_, index) => (
      <AccordionItem
        key={index}
        borderRadius="xl"
        border="1px solid"
        borderColor="gray.200"
        mb={3}
        padding={3}
      >
        <Skeleton height="20px" mb={3} />
      </AccordionItem>
    ))
  ) : (filteredProblems
    .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
    .map((passage) => (
      <AccordionItem key={passage.id} borderRadius="xl" border="1px solid" borderColor="gray.200" mb={3}>
        <h2>
          <AccordionButton borderRadius="xl">
            <Box flex="1" textAlign="left">
              {isLoading ? (
                <Skeleton height="20px" width="60%" />
              ) : (
                <Flex align="center">
                  <Text size="md">
                    {passage.title || "지문 제목"}
                  </Text>
                  <PassageRating
                    passage={passage}
                    onUpdate={(updatedPassage) => {
                      setFilteredProblems((prevProblems) =>
                        prevProblems.map((p) =>
                          p.id === updatedPassage.id ? { ...p, ...updatedPassage } : p
                        )
                      );
                    }}
                  />
                </Flex>
              )}
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>

        <AccordionPanel pb={4}>
          <HStack spacing={4}>
            <Box
              flex="1"
              mb={4}
              border="1px solid"
              borderColor="gray.300"
              borderRadius="lg"
              p={4}
              height="900px"
              overflow="auto"
            >
              {passage.contents
                ?.split("\n\n")
                .slice(0, Math.ceil(passage.contents.split("\n\n").length / 2))
                .map((paragraph, index) => (
                  <Text
                    key={index}
                    size="sm"
                    bg={inputBg}
                    borderRadius="xl"
                    textAlign="justify"
                    style={{
                      whiteSpace: "pre-wrap",
                      letterSpacing: "0.08em",
                      wordSpacing: "0.15em",
                      wordBreak: "break-word",
                    }}
                  >
                    {paragraph}
                  </Text>
                ))}
            </Box>

            <Box
              flex="1"
              mb={4}
              border="1px solid"
              borderColor="gray.300"
              borderRadius="lg"
              p={4}
              height="900px"
              overflow="auto"
            >
              {passage.contents
                ?.split("\n\n")
                .slice(Math.ceil(passage.contents.split("\n\n").length / 2))
                .map((paragraph, index) => (
                  <Text
                    key={index}
                    size="sm"
                    bg={inputBg}
                    borderRadius="xl"
                    textAlign="justify"
                    style={{
                      whiteSpace: "pre-wrap",
                      letterSpacing: "0.08em",
                      wordSpacing: "0.15em",
                    }}
                  >
                    {paragraph}
                  </Text>
                ))}
            </Box>
          </HStack>

          {passage.feedback && (
            <Box mt={4} p={3} bg="gray.50" borderRadius="md">
              <Text fontSize="sm" fontWeight="bold" color="gray.600">피드백:</Text>
              <Text fontSize="sm" color="gray.700">{passage.feedback}</Text>
            </Box>
          )}

          <List spacing={3} mt={4}>
            {passage.questions.map((question) => (
              <ListItem
                key={question.id}
                borderWidth="1px"
                borderRadius="xl"
                p={3}
                boxShadow="md"
                _hover={{ boxShadow: "lg", bg: "gray.50" }}
                transition="all 0.3s ease"
              >
                {/* <Checkbox
                  colorScheme="teal"
                  onChange={(event) => checkHandler(question, event)}
                  mr={4}
                /> */}
                <Box flex="1" textAlign="left">
                  <Text fontSize="md" fontWeight="bold" color="gray.800">
                    {question.question}
                  </Text>
                  {question.supplement && (
                    <Box bg="white" borderWidth="1px" borderRadius="lg" p={3} whiteSpace="pre-wrap" mt={3}>
                      {question.supplement}
                    </Box>
                  )}
                  <Text mt={2} color="gray.600">1. {question.choice_1}</Text>
                  <Text color="gray.600">2. {question.choice_2}</Text>
                  <Text color="gray.600">3. {question.choice_3}</Text>
                  <Text color="gray.600">4. {question.choice_4}</Text>
                  <Text color="gray.600">5. {question.choice_5}</Text>
                </Box>
              </ListItem>
            ))}
          </List>
        </AccordionPanel>
      </AccordionItem>
    )))}
</Accordion>


{/* Pagination 추가 */}
<Pagination
  totalItems={Object.keys(filteredProblems).length}
  currentPage={currentPage}
  onPageChange={(page) => setCurrentPage(page)}
/>
          {/* <Flex paddingTop="20" justifyContent="center">
            <Button
              onClick={createWorkbook}
              colorScheme="teal"
              isDisabled={!(Array.from(checkedItems).length > 0)}
              isLoading={isLoading}
              _hover={{ bg: "teal.400" }}
              borderRadius="full"
            >
              문제집 만들기
            </Button>
          </Flex> */}

          {/* <Box
            mt={10}
            p={5}
            borderWidth="1px"
            borderRadius="xl"
            bg="gray.50"
            boxShadow="md"
          >
            <Heading size="sm" mb={4} color="teal.500">
              선택된 문제:
            </Heading>
            <List spacing={3}>
              {Array.from(checkedItems).map((item) => (
                <ListItem
                  key={item.id}
                  borderWidth="1px"
                  borderRadius="xl"
                  p={3}
                  bg="white"
                  boxShadow="md"
                >
                  <Heading size="sm" color="gray.800">
                    {item.question}
                  </Heading>
                  <Text fontSize="sm" color="gray.600">
                    {item.question}
                  </Text>
                </ListItem>
              ))}
              {Array.from(checkedItems).length === 0 && (
                <Text fontSize="sm" color="gray.600">
                  선택된 문제가 없습니다.
                </Text>
              )}
            </List>
          </Box> */}
        </Box>
      </Grid>
    </Flex>
  );
}
