import {
  Flex,
  Box,
  Heading,
  Text,
  Button,
  VStack,
  Grid,
  HStack,
  Input,
  Tag,
  TagLabel,
  TagCloseButton,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import apiClient from "../../../api/axiosInstance"; // 생성한 Axios 인스턴스를 import
import { useNavigate, useLocation } from "react-router-dom";

export default function NovelExtractLiteratureDashboard() {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedWork, setSelectedWork] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [keyword, setKeyword] = useState("");
  const [isRetrieving, setIsRetrieving] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [retrievedTexts, setretrievedTexts] = useState([]);
  const [namespace, setNamespace] = useState(false);
  const [theme, setTheme] = useState(""); // New state for theme input
  const [themes, setThemes] = useState([]); // New state for multiple themes
  const [selectedParts, setselectedParts] = useState([]);

  useEffect(() => {
    // Check if work and category are passed in from the previous page
    if (location.state) {
      const { work, category } = location.state;

      if (!work || !category) {
        // Redirect to a specific error page with the error message
        navigate("/error", {
          state: {
            errorMessage:
              "문학 작품이 선택되지 않았습니다. 먼저 문학 작품을 선택해주세요.",
          },
        });
      } else {
        // Set the selected work if both work and category exist
        setSelectedWork(work);
        setSelectedCategory(category);
      }
    } else {
      // Redirect to error if location.state is not present
      navigate("/error", {
        state: {
          errorMessage:
            "문학 작품이 선택되지 않았습니다. 먼저 문학 작품을 선택해주세요.",
        },
      });
    }
  }, [location.state, navigate]);

  const handleRetrieveContent = () => {
    if (keyword.trim() === "") return;

    setIsRetrieving(true); // Disable the button while processing
    // API call for poetry
    apiClient
      .post(
        "/problem_generator/retrieve_similar_novels/", // 상대 경로만 사용
        {
          text: keyword,
          selected_text: selectedWork.title,
          author: selectedWork.author,
        }
      )
      .then((response) => {
        setretrievedTexts(response.data.retrieved_texts);
        setNamespace(response.data.namespace);
      })
      .catch((error) => {
        console.error(
          "Error fetching similar poems:",
          error.response?.data || error.message
        );
      })
      .finally(() => {
        setIsRetrieving(false); // Re-enable the button when request is completed
      });
  };

  const handlePartSelection = (part) => {
    if (selectedParts.includes(part)) {
      setselectedParts(selectedParts.filter((t) => t !== part)); // Deselect if clicked again
    } else {
      if (selectedParts.length < 2) {
        setselectedParts([...selectedParts, part]); // Add to selected texts if less than 2
      } else {
        setselectedParts([selectedParts[1], part]);
      }
    }
  };

  const handleAddTheme = () => {
    if (theme.trim() !== "") {
      setThemes([...themes, theme]); // Add the new theme to the array
      setTheme(""); // Clear the input after adding
    }
  };

  const handleRemoveTheme = (index) => {
    const updatedThemes = themes.filter((_, i) => i !== index);
    setThemes(updatedThemes); // Remove a theme from the array
  };

  const categoryMap = {
    modern_novel: "문학 작품 - 현대 소설",
    classic_novel: "문학 작품 - 고전 소설",
  };

  const handleCompleteSelection = async () => {
    setIsGenerating(true);
    // Step 1: Reorder selectedParts based on the number at the beginning of the text
    if (selectedParts.length === 2 && themes.length > 0) {
      const reorderedParts = selectedParts.sort((a, b) => {
        // Extract the number from the start of the text field in each part
        const numberA = parseInt(a.text.match(/^\d+/)?.[0], 10); // Extract number from part a's text
        const numberB = parseInt(b.text.match(/^\d+/)?.[0], 10); // Extract number from part b's text
        return numberA - numberB; // Sort based on the extracted numbers
      });

      // Step 2: Prepare the text fields and data for the request
      const [firstPart, secondPart] = reorderedParts.map((part) => part.text);
      const initialTitle = selectedWork.title;
      const initialAuthor = selectedWork.author;
      const custom = themes.join(", ");
      const passageId = selectedWork.id;
      const option = categoryMap[selectedCategory]; // Use the category mapping

      try {
        // Step 3: Make a POST request to the backend using apiClient
        const response = await apiClient.post(
          "/problem_generator/generate_sup_keyword_novels/", // baseURL이 설정된 경우 상대 경로로 사용
          {
            title: initialTitle,
            author: initialAuthor,
            first: firstPart,
            second: secondPart,
            custom: custom,
            namespace: namespace,
            category: option,
          }
        );
        // Step 4: Extract the passage and keywords from the response
        const { passage, id, keywords } = response.data;

        // Function to clean keywords only if necessary
        const cleanResponse = (orgKeywords) => {
          // Check if the text starts with "{" and ends with "}"
          if (orgKeywords.startsWith("{") && orgKeywords.endsWith("}")) {
            return orgKeywords; // Return as-is if already in the correct format
          }

          // Otherwise, clean the text by removing everything outside the braces
          const startIndex = orgKeywords.indexOf("{");
          const endIndex = orgKeywords.lastIndexOf("}") + 1;

          // Check if both braces exist in the keywords string
          if (startIndex !== -1 && endIndex !== -1) {
            return orgKeywords.slice(startIndex, endIndex); // Return only the content inside the braces
          }

          return orgKeywords; // Return original string if no valid braces found
        };

        const cleanedKeywords = cleanResponse(keywords);
        // Since 'keywords' comes as a string, parse it to extract the categories
        let keywordsObjects = [];

        try {
          // Attempt to parse the keywords string as JSON
          const keywordsParsed = JSON.parse(cleanedKeywords);

          keywordsObjects = Object.entries(keywordsParsed).map(
            ([key, text]) => ({
              key: key.trim(),
              text: `${key.trim()}: ${text.trim()}`,
            })
          );
        } catch (e) {
          console.error("Error parsing keywords:", e);
          alert("키워드 데이터를 파싱하는 도중 문제가 발생했습니다.");
          setIsGenerating(false);
          return;
        }
        setIsGenerating(false);
        // Log the keywordsObjects to debug

        // Step 5: Navigate to the next page with the received data
        console.log(passage);

        const title = `${selectedWork.author}, 「${selectedWork.title}」`;
        navigate("/novel-sup-create/", {
          state: {
            title,
            passage,
            keywords: keywordsObjects,
            passage_id: id,
            option,
            custom,
          },
        });
      } catch (error) {
        console.error("Error generating supplementary keywords:", error);
        setIsGenerating(false);
        alert(
          "보충 정보를 생성하는 도중 문제가 발생했습니다. 다시 시도해 주세요."
        );
      }
    }
  };

  return (
    <Flex
      flexDirection="column"
      pt={{ base: "120px", md: "75px" }}
      px={{ base: 4, md: 8 }}
    >
      <Grid
        templateColumns={{ md: "1fr", lg: "1fr" }}
        templateRows={{ md: "1fr auto", lg: "1fr" }}
        my="26px"
        gap="24px"
      >
        <Box>
          <Heading size="lg" color="teal.600">
            키워드 입력
          </Heading>
          <Text color="gray.600" mt={2}>
            지문 발췌를 위한 키워드 문구를 입력해주세요
          </Text>
        </Box>
        {selectedWork && (
          <HStack spacing={0} w="100%" alignItems="stretch">
            {/* Selected Work Section */}
            <HStack flex="6">
              <Box px={6} w="100%">
                <Box
                  borderWidth="1px"
                  borderRadius="lg"
                  p={4}
                  boxShadow="md"
                  bg="gray.50"
                  _hover={{
                    boxShadow: "lg",
                    bg: "gray.50",
                  }}
                  transition="all 0.3s ease"
                >
                  <HStack justify="space-between" align="center">
                    <VStack align="start" spacing={2}>
                      <Text fontSize="lg" fontWeight="bold" color="teal.700">
                        {selectedWork.author}, 「{selectedWork.title}」
                      </Text>
                      <Text color="gray.500">
                        {selectedWork.description || "작품 설명(EBS 연계 등)"}
                      </Text>
                    </VStack>

                    <HStack>
                      <Button
                        borderRadius="full"
                        colorScheme="red"
                        onClick={() =>
                          navigate("/passage-literature-create/", {
                            state: { initialCategory: selectedCategory },
                          })
                        }
                      >
                        작품 재선택
                      </Button>
                    </HStack>
                  </HStack>
                </Box>
              </Box>
            </HStack>

            {/* Keyword Input Section */}
            <VStack flex="4" w="100%" h="100%" spacing={2}>
              <Input
                placeholder="키워드 문구 입력"
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
                textAlign="center"
                flex="1"
              />
              <Button
                borderRadius="full"
                colorScheme="teal"
                onClick={handleRetrieveContent}
                isDisabled={isRetrieving}
                isLoading={isRetrieving}
                flex="1"
              >
                지문 발췌
              </Button>
            </VStack>
          </HStack>
        )}
        {retrievedTexts.length > 0 && !isRetrieving && (
          <>
            <Box>
              <Heading size="lg" color="teal.600">
                지문 선택
              </Heading>
              <Text color="gray.600" mt={2}>
                보기 생성을 위한 지문 2개를 선택해주세요
              </Text>
            </Box>

            {/* Display 10 Retrieved Texts in a Grid */}
            <Grid
              templateColumns={{
                base: "1fr",
                md: "repeat(2, 1fr)",
                lg: "repeat(2, 1fr)",
              }}
              gap={4}
            >
              {retrievedTexts.map((part, index) => (
                <Box
                  key={index}
                  bg="white"
                  borderWidth="1px"
                  borderRadius="lg"
                  p={2}
                  boxShadow="md"
                  onClick={() => handlePartSelection(part)}
                  cursor="pointer"
                  border={
                    selectedParts.includes(part)
                      ? "2px solid teal"
                      : "1px solid gray"
                  }
                  _hover={{ border: "2px solid teal" }}
                  transition="all 0.3s ease"
                  height="300px" // Adjust the height as needed
                  overflowY="auto" // Makes the content scrollable vertically
                >
                  <Text color="gray.600" whiteSpace="pre-wrap">
                    {part.text.replace(/^\d+\.\s*/, "")}
                  </Text>
                </Box>
              ))}
            </Grid>

            {/* Add '보기 테마' Input Section */}
            <Box h="100%">
              <HStack
                spacing={4}
                textAlign="center"
                justifyContent="center"
                display="flex"
                w="100%"
              >
                {/* Title and Description */}
                <VStack align="flex-start" spacing={2} flex="3">
                  <Heading size="md" color="teal.600">
                    보기 테마 입력
                  </Heading>
                  <Text color="gray.600">
                    보기에 사용할 테마를 입력해주세요
                  </Text>
                </VStack>

                {/* Theme Input and Add Button */}
                <HStack spacing={2} flex="4" justify="center" h="100%">
                  <Input
                    placeholder="테마 입력"
                    value={theme}
                    onChange={(e) => setTheme(e.target.value)}
                    textAlign="center"
                    w="100%"
                  />
                  <Button colorScheme="teal" onClick={handleAddTheme}>
                    <Text px={1}>테마 추가</Text>
                  </Button>
                </HStack>

                {/* Display Added Themes */}
                {themes.length >= 0 && (
                  <VStack align="flex-start" flex="6">
                    <Heading size="sm" color="teal.600">
                      추가된 테마:
                    </Heading>
                    <HStack wrap="wrap">
                      {themes.map((theme, index) => (
                        <Tag
                          size="lg"
                          key={index}
                          borderRadius="full"
                          variant="solid"
                          colorScheme="teal"
                          m={1}
                        >
                          <TagLabel>{theme}</TagLabel>
                          <TagCloseButton
                            onClick={() => handleRemoveTheme(index)}
                          />
                        </Tag>
                      ))}
                    </HStack>
                  </VStack>
                )}
              </HStack>
            </Box>

            {/* Complete Selection Button */}
            <Box textAlign="center" mt={4}>
              <Button
                colorScheme="teal"
                onClick={handleCompleteSelection}
                isDisabled={selectedParts.length !== 2 || themes.length === 0}
                isLoading={isGenerating}
              >
                선택 완료
              </Button>
            </Box>
          </>
        )}
      </Grid>
    </Flex>
  );
}
