import {
  Flex,
  Box,
  Heading,
  Text,
  Button,
  VStack,
  Skeleton,
  Stack,
  Grid,
  Input,
  SimpleGrid,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import axios from "axios";
import apiClient from "../../../api/axiosInstance"; // 생성한 Axios 인스턴스를 import

import { useNavigate } from "react-router-dom";

export default function WorkbookListDashboard() {
  const [workbooks, setWorkbooks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    apiClient.get(`/problem_generator/workbooks/`)
      .then(response => {
        setWorkbooks(response.data);
        setIsLoading(false);
      })
      .catch(error => {
        console.error("Error fetching workbooks:", error);
        setIsLoading(false);
      });
  }, []);

  const viewWorkbook = (workbook) => {
    navigate('/workbook-detail', { state: { workbook } });
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredWorkbooks = workbooks.filter(workbook =>
    workbook.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
    workbook.description.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }} px={{ base: 4, md: 8 }}>
       <Grid
        templateColumns={{ md: "1fr", lg: "1fr" }}
        templateRows={{ md: "1fr auto", lg: "1fr" }}
        my="26px"
        gap="24px"
      >
      <Box mb={8}>
        <Heading size="lg" color="teal.600">문제집 목록</Heading>
        <Text color="gray.600" mt={2}>여기에서 다양한 문제집을 찾아볼 수 있습니다.</Text>
      </Box>

      {/* 검색 입력 필드 */}
      <Input
        placeholder="검색어를 입력하세요"
        value={searchQuery}
        onChange={handleSearchChange}
        mb={5}
        borderRadius="full"
      />

        <Box bg="white" borderWidth="1px" borderRadius="lg" p={4} boxShadow="md">
          {isLoading ? (
            <SimpleGrid columns={{ base: 1, sm: 2, md: 3 }} spacing={5}>
              {[1, 2, 3, 4, 5, 6].map((_, index) => (
                <Skeleton height="150px" borderRadius="lg" key={index} />
              ))}
            </SimpleGrid>
          ) : (
            filteredWorkbooks.length > 0 ? (
              <SimpleGrid columns={{ base: 1, sm: 2, md: 3 }} spacing={5}>
                {filteredWorkbooks.map(workbook => (
                  <Box key={workbook.id} borderWidth="1px" borderRadius="lg" p={3} boxShadow="sm" _hover={{ boxShadow: "md", bg: "gray.50" }} transition="all 0.3s ease">
                    <VStack align="start" spacing={2}>
                      <Heading size="md" color="teal.700">{workbook.title}</Heading>
                      <Text color="gray.700">{workbook.description}</Text>
                      <Button colorScheme="teal" variant="outline" alignSelf="flex-end" onClick={() => viewWorkbook(workbook)} borderRadius="full">
                        보기
                      </Button>
                    </VStack>
                  </Box>
                ))}
              </SimpleGrid>
            ) : (
              <Text textAlign="center">검색 결과가 없습니다.</Text>
            )
          )}
        </Box>
      </Grid>
    </Flex>
  );
}
