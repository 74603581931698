import {
  Flex,
  Grid,
  Box,
  Center,
  Button,
  Heading,
  useToast,
  Input,
  Select,
  Textarea,
  FormControl,
  FormLabel,
  HStack,
  VStack,
  IconButton,
} from "@chakra-ui/react";
import { AddIcon, CloseIcon } from "@chakra-ui/icons";
import React, { useCallback, useState, useEffect } from "react";
import QuestionChoices from "./QuestionChoices";
import QuestionTitle from "./QuestionTitle";
import QuestionBoxContent from "./QuestionBoxContent";

export default function QuestionEditorComponent(props) {

  return (
    <Box
      bg="white"
      m={1}
      p={5}
      shadow={"md"}
      borderWidth="1px"
      borderRadius="lg"
    >
      <FormControl id="question-content-box">
        <FormLabel>
          <Flex flexDirection="row" justifyContent="space-between">
            <Select
              defaultValue={props.category}
              onChange={(e) => {
                // setData({category: e.target.value });
                props.handleComponentCategoryChange({
                  id: props.id,
                  category: e.target.value,
                });
              }}
              fontWeight="bold"
              width="25%"
            >
              {props.componentCategories.map((option, i) => (
                <option key={i} value={option}>
                  {option}
                </option>
              ))}
            </Select>

            <IconButton
              size="sm"
              aria-label="Remove question"
              icon={<CloseIcon />}
              onClick={() => {
                props.handleRemove(props.id);
              }}
            />
          </Flex>
        </FormLabel>

        {(props.category === props.componentCategories[0]) && (
          <QuestionTitle id={props.id} handleDataAdd={props.handleDataAdd} />
        )}
        {props.category === props.componentCategories[1] && (
          <QuestionBoxContent
            id={props.id}
            handleDataAdd={props.handleDataAdd}
          />
        )}
        {props.category === props.componentCategories[2] && (
          <QuestionChoices id={props.id} handleDataAdd={props.handleDataAdd} />
        )}
      </FormControl>
    </Box>
  );
}
