import {
  Flex,
  Box,
  Heading,
  Text,
  VStack,
  SimpleGrid,
  Grid,
  GridItem,
  Skeleton,
  Button,
  useToast,
  Checkbox,
  Center,
  IconButton,
} from "@chakra-ui/react";
import { useNavigate, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import axios from "axios";
import apiClient from "../../../api/axiosInstance"; // 생성한 Axios 인스턴스를 import

import { CopyIcon } from '@chakra-ui/icons';

export default function WorkbookDetail() {
  const location = useLocation();
  const { workbook } = location.state || { workbook: { title: "", description: "", questions: [] } };
  const toast = useToast();
  const navigate = useNavigate();
  const [combinedContent, setCombinedContent] = useState([]);
  const [passages, setPassages] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isGenerating, setIsGenerating] = useState(false);
  const [generatedQuestions, setGeneratedQuestions] = useState([]);
  const [solutions, setSolutions] = useState([]);
  const [checkedBoxList, setCheckedBoxList] = useState([]);

  useEffect(() => {
    const fetchPassages = async () => {
      setIsLoading(true);
    // localStorage에서 JWT 토큰을 가져옵니다.
      const token = localStorage.getItem("token");

      const passageIds = Array.from(new Set(workbook.questions.map(q => q.passage)));
      const passageResponses = await Promise.all(passageIds.map(id =>
        apiClient.get('/problem_generator/passages/${id}')
      ));

      const passagesData = passageResponses.reduce((acc, res) => {
        acc[res.data.id] = res.data;
        return acc;
      }, {});

      setPassages(passagesData);
      setIsLoading(false);
    };

    fetchPassages();
  }, [workbook]);

  useEffect(() => {
    const combined = [];
    const seenPassages = new Set();

    workbook.questions.forEach(question => {
      const passageId = question.passage;

      if (passageId && !seenPassages.has(passageId) && passages[passageId]) {
        const passageData = passages[passageId];
        combined.push({ type: 'passage', data: passageData });
        seenPassages.add(passageId);
      }

      combined.push({ type: 'question', data: question });
    });

    setCombinedContent(combined);
  }, [workbook, passages]);

  const handleGenerateSimilarQuestions = async () => {
    setIsGenerating(true);
    try {
      const response = await apiClient.post(`/problem_generator/generate_similar_questions/`, {
        workbook,
        passages
      });
      setGeneratedQuestions(response.data.questions);
      setSolutions(response.data.solutions)
      toast({
        title: "변형 문제 생성 완료",
        description: "유사한 문제가 성공적으로 생성되었습니다.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error generating similar questions:", error);
      toast({
        title: "변형 문제 생성 실패",
        description: "유사한 문제 생성 중 오류가 발생했습니다.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsGenerating(false);
    }
  };

  const handleCheckboxChange = (e, index) => {
    if (e.target.checked) {
      setCheckedBoxList((prevList) => [...prevList, index]);
    } else {
      setCheckedBoxList((prevList) => prevList.filter(item => item !== index));
    }
  };

  const saveSelectedQuestions = () => {
    const selectedQuestions = checkedBoxList.map(index => generatedQuestions[index]);
    const selectedSolutions = checkedBoxList.map(index => solutions[index]);
    const data = {
      questions: selectedQuestions,
      solutions: selectedSolutions,
      title: workbook.title
    };

    setIsLoading(true);

    apiClient.post(`/problem_generator/save_similar_questions/`, data)
      .then(response => {
        toast({
          title: "문제가 성공적으로 저장되었습니다.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        navigate('/workbook-list');
      })
      .catch(err => {
        console.log(err);
        toast({
          title: "문제 저장에 실패했습니다.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        toast({
          title: "복사 성공",
          description: "내용이 클립보드에 복사되었습니다.",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      })
      .catch(err => {
        toast({
          title: "복사 실패",
          description: "클립보드에 복사할 수 없습니다.",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      });
  };

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <Grid templateColumns={{ md: "1fr", lg: "1fr" }} templateRows={{ md: "1fr auto", lg: "1fr" }} my="26px" gap="24px">
        <Box bg="white" borderWidth="1px" borderRadius="lg" p={5}>
          {/* <Button colorScheme="teal" onClick={handleGenerateSimilarQuestions} isLoading={isGenerating} borderRadius="full">
            변형문제 만들기
          </Button> */}
          <Heading size="md" mb={5} textAlign="center">{workbook.title}</Heading>
          <Text mb={5} textAlign="center">{workbook.description}</Text>

          {isLoading ? (
            <SimpleGrid columns={2} spacing={10}>
              <Skeleton height="500px" borderRadius="lg" />
              <Skeleton height="500px" borderRadius="lg" />
            </SimpleGrid>
          ) : (
            combinedContent.length > 0 ? (
              <SimpleGrid columns={2} spacing={10}>
                {combinedContent.map((item, index) => (
                  <React.Fragment key={index}>
                    {item.type === 'passage' && (
                      <GridItem colSpan={2}>
                        <Box borderWidth="1px" borderRadius="lg" p={4} mb={4} position="relative">
                          <IconButton
                            icon={<CopyIcon />}
                            position="absolute"
                            top="10px"
                            right="10px"
                            aria-label="Copy passage"
                            size="sm"
                            onClick={() => copyToClipboard(item.data?.contents || '')}
                          />
                          <Heading size="sm">지문: {item.data?.title || '지문 제목'}</Heading>
                          <Text mt={3} whiteSpace="pre-wrap">{item.data?.contents || '지문 내용이 없습니다.'}</Text>
                        </Box>
                      </GridItem>
                    )}
                    {item.type === 'question' && (
                      <GridItem>
                        <Box borderWidth="1px" borderRadius="lg" p={4} mb={4} position="relative">
                          <IconButton
                            icon={<CopyIcon />}
                            position="absolute"
                            top="10px"
                            right="10px"
                            aria-label="Copy question"
                            size="sm"
                            onClick={() => {
                              const fullQuestionText = `문제: ${item.data.question}\n보기:${item.data.supplement}\n1. ${item.data.choice_1}\n2. ${item.data.choice_2}\n3. ${item.data.choice_3}\n4. ${item.data.choice_4}\n5. ${item.data.choice_5}`;
                              copyToClipboard(fullQuestionText);
                            }}
                          />
                          <Heading size="sm">문제 {index + 1}: {item.data.question}</Heading>
                          {item.data.supplement ? 
                            <Box bg="white" borderWidth="1px" borderRadius="lg" p={3} whiteSpace="pre-wrap" mt={3}>
                          {item.data.supplement}
                          </Box> : <></> }
                          <VStack align="start" mt={3} spacing={2}>
                            <Text>1. {item.data.choice_1}</Text>
                            <Text>2. {item.data.choice_2}</Text>
                            <Text>3. {item.data.choice_3}</Text>
                            <Text>4. {item.data.choice_4}</Text>
                            <Text>5. {item.data.choice_5}</Text>
                          </VStack>
                        </Box>
                      </GridItem>
                    )}
                  </React.Fragment>
                ))}
              </SimpleGrid>
            ) : (
              <Text textAlign="center">선택된 문제가 없습니다.</Text>
            )
          )}
        </Box>
      </Grid>

      {generatedQuestions.length > 0 && (
        <Grid templateColumns={{ md: "1fr", lg: "1fr" }} templateRows={{ md: "1fr auto", lg: "1fr" }} my="26px" gap="24px">
          <Box bg="white" borderWidth="1px" borderRadius="lg" p={3}>
            <Box p={3}>
              <Center>
                <Heading size="md">생성된 변형 문제 및 해설</Heading>
              </Center>
            </Box>
            {generatedQuestions.map((question, index) => (
              <Box key={index} bg="gray.50" borderWidth="1px" borderRadius="lg" p={3} mb={4}>
                <Checkbox colorScheme="teal" size="lg" mb={2} onChange={(e) => handleCheckboxChange(e, index)} />
                <Heading size="sm">문제 {index + 1}: {question.question}</Heading>
                
                <VStack spacing={2} mt={2} alignItems="start">
                {question.supplement ? 
                            <Box bg="white" borderWidth="1px" borderRadius="lg" p={3} whiteSpace="pre-wrap" mt={3}>
                          {question.supplement}
                          </Box> : <></> }
                  <Text>1. {question.choice_1}</Text>
                  <Text>2. {question.choice_2}</Text>
                  <Text>3. {question.choice_3}</Text>
                  <Text>4. {question.choice_4}</Text>
                  <Text>5. {question.choice_5}</Text>
                  <Text fontWeight="bold">정답: {solutions[index]?.answer}</Text>
                  <Text mt={2} fontWeight="bold">해설:</Text>
                  <Text>{solutions[index]?.explanation}</Text>
                </VStack>
              </Box>
            ))}
            {checkedBoxList.length > 0 && (
              <Center>
                <Button colorScheme="teal" isLoading={isGenerating} onClick={saveSelectedQuestions} borderRadius="full">
                  선택된 문제 저장
                </Button>
              </Center>
            )}
          </Box>
        </Grid>
      )}
    </Flex>
  );
}
