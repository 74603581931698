import {
  Flex,
  Grid,
  Box,
  Center,
  Button,
  Heading,
  useToast,
  Input,
  Select,
  Textarea,
  FormControl,
  FormLabel,
  FormHelperText,
  FormErrorMessage,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Checkbox,
  CheckboxGroup,
  Stack,
  Radio,
  RadioGroup,
  HStack,
  VStack,
  IconButton,
} from "@chakra-ui/react";
import { AddIcon, CloseIcon } from "@chakra-ui/icons";
import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./ExamPaperEditorDashboard.module.css";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Font,
  BlobProvider,
} from "@react-pdf/renderer";
import QuestionChoices from "./components/QuestionChoices";
import QuestionBoxContent from "./components/QuestionBoxContent";
import QuestionTitle from "./components/QuestionTitle";
import QuestionEditorComponent from "./components/QuestionEditorComponent";
import QuestionEditor from "./components/QuestionEditor";

import KoPubWorldBatang from "../../../fonts/KoPubWorld Batang Medium.ttf";
import KoPubWorldDotum from "../../../fonts/KoPubWorld Dotum Medium.ttf";
import NanumMyeongjo from "../../../fonts/NanumMyeongjo.ttf";

export default function ProblemBankDashboard() {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [category, setCategory] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const toast = useToast();
  const navigate = useNavigate();
  const categories = ["전체", "화법", "작문", "언어(문법)", "독서", "문학"];
  const [questionComponents, setQuestionComponents] = useState([{}]);



  // ---------------- pdf viewer ----------------

  // Register font
  Font.register({
    family: "KoPubWorldBatang",
    src: KoPubWorldBatang,
  });
  Font.register({
    family: "KoPubWorldDotum",
    src: KoPubWorldDotum,
  });
  Font.register({
    family: "NanumMyeongjo",
    src: NanumMyeongjo,
  });

  // 단위는 mm로 하고싶다면 : mm, cm, in, px
  // 단위를 지정하지 않으면 기본 단위인 pt로 설정됩니다.
  // 단위를 지정하는 방법:

  const pdfStyles = StyleSheet.create({
    page: {
      flexDirection: "column",
      backgroundColor: "#ffffff",
      paddingTop: 15 + "mm",
      paddingRight: 15 + "mm",
      paddingBottom: 15 + "mm",
      paddingLeft: 15 + "mm",
      fontSize: 12,
      fontFamily: "NanumMyeongjo",
      lineHeight: 1.4,
    },
    header: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "baseline",
      borderBottomWidth: 2 + "px",
      borderBottomStyle: "solid",
      borderBottomColor: "#333",
      paddingBottom: 1 + "mm",
      fontFamily: "KoPubWorldBatang",
    },
    headerLeft: {
      fontSize: 17,
      textAlign: "left",
      flex: 1,
    },
    headerCenter: {
      fontSize: 22,
      textAlign: "center",
      flex: 1,
    },
    headerRight: {
      fontSize: 17,
      textAlign: "right",
      flex: 1,
    },
    sections: {
      flexGrow: 1,
      flexDirection: "row",
      justifyContent: "space-between",
    },
    leftSection: {
      margin: 0,
      paddingTop: 10,
      paddingRight: 10,
      paddingBottom: 10,
      paddingLeft: 1,
      width: "50%",
    },
    rightSection: {
      margin: 0,
      paddingTop: 10,
      paddingRight: 1,
      paddingBottom: 10,
      paddingLeft: 10,
      width: "50%",
      borderLeftWidth: 2 + "px",
      borderLeftStyle: "solid",
      borderLeftColor: "#333",
    },
    mainHeader: {
      fontSize: 20,
      textAlign: "center",
      marginBottom: 20,
      boxSize: "border-box",
      borderColor: "#333",
    },
    mainText: {
      fontSize: 12,
      color: "#333",
    },

    statementBlock: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "left",
      alignItems: "baseline",
    },
    questionNumber: {
      fontSize: 13,
      marginRight: 5,
    },
    statement: {
      textAlign: "left",
      marginBottom: 8,
      boxSize: "border-box",
      borderColor: "#333",
    },
    box: {
      border: "1px solid black",
      padding: 5,
      marginBottom: 15,
    },
    boxText: {
      // 양쪽 정렬
      textAlign: "justify",
      color: "#333",
    },
    choices: {
      flexDirection: "column",
      justifyContent: "space-between",
      marginLeft: 10,
      marginBottom: 50,
    },
    choice: {
      textAlign: "left",
      marginBottom: 1,
    },

    footer: {
      flexDirection: "row",
      justifyContent: "space-between",
      borderTopWidth: 1 + "px",
      borderTopStyle: "solid",
      borderTopColor: "#aaaaaa",
      paddingTop: 3 + "mm",
    },
    footerLeft: {
      flex: 1,
      fontSize: 10,
      textAlign: "left",
      fontFamily: "KoPubWorldBatang",
    },
    footerRight: {
      flex: 1,
      fontSize: 10,
      textAlign: "right",
    },
    pageText: {
      fontSize: 11,
      flexDirection: "row",
      justifyContent: "space-between",
      fontFamily: "KoPubWorldBatang",
    },
    pageTextLeft: {
      textAlign: "left",
    },
    pageTextCenter: {
      textAlign: "center",
    },
    pageTextRight: {
      textAlign: "right",
    },
  });

  const MyQuestion = () => (
    <View style={pdfStyles.question}>
      <View style={pdfStyles.statementBlock}>
        <Text style={pdfStyles.questionNumber}>{3}.</Text>
        <Text style={pdfStyles.statement}>다음 글을 읽고 물음에 답하시오.</Text>
      </View>
      <View style={pdfStyles.box}>
        <Text style={pdfStyles.boxText}>
          한비자는 중국 전국 시대의 한비자가 제시한 사상이 담긴 저작이다. 여러
          나라가 패권을 다투던 혼란기를 맞아 엄격한 법치를 통해 부국강병을 꾀한
          한비자는 노자에 대한 해석을 통해 자신의 법치 사상을 뒷받침했고, 이러한
          면모는 한비자의 해로, 유로 등에서 확인할 수 있다. 한비자는 중국 전국
          시대의 한비자가 제시한 사상이 담긴 저작이다. 여러 나라가 패권을 다투던
          혼란기를 맞아 엄격한 법치를 통해 부국강병을 꾀한 한비자는 노자에 대한
          해석을 통해 자신의 법치 사상을 뒷받침했고, 이러한 면모는 한비자의
          해로, 유로 등에서 확인할 수 있다.
        </Text>
      </View>
      <View style={pdfStyles.statementBlock}>
        <Text style={pdfStyles.questionNumber}>{3}.</Text>
        <Text style={pdfStyles.statement}>
          윗 글을 참고할 때, 다음 중 옳지 않은 것은?
        </Text>
      </View>
      <View style={pdfStyles.choices}>
        <Text style={pdfStyles.choice}>
          {"①"} 한비자의 사상은 법치 사상을 주장했다. 그리고 이는 노자의 해석을
          통해 뒷받침되었다.
        </Text>
        <Text style={pdfStyles.choice}>{"②"} 한비자는 노자를 해석하였다. </Text>
        <Text style={pdfStyles.choice}>{"③"} 한비자는 부국강병을 꾀했다. </Text>
        <Text style={pdfStyles.choice}>
          {"④"} 한비자의 사상은 유로 등에서 확인할 수 있다.
        </Text>
        <Text style={pdfStyles.choice}>
          {"⑤"} 한비자는 중국 전국 시대의 한비자가 제시한 사상이 담긴 저작이다.
        </Text>
      </View>
    </View>
  );

  const MyDocument = () => (
    <Document>
      <Page size={"A3"} style={pdfStyles.page}>
        <View style={pdfStyles.header}>
          <Text style={pdfStyles.headerLeft}>홀수형</Text>
          <Text style={pdfStyles.headerCenter}>국어 영역</Text>
          <Text style={pdfStyles.headerRight}>3</Text>
        </View>
        <View style={pdfStyles.sections}>
          <View style={pdfStyles.leftSection}>
            <MyQuestion />
            <MyQuestion />
            {/* <Text style={pdfStyles.mainHeader}>Left Section</Text>
            <Text style={pdfStyles.mainText}>
              Content on the left side of the vertical line.
            </Text> */}
          </View>
          <View style={pdfStyles.rightSection}>
            <Text style={pdfStyles.mainHeader}>Right Section</Text>
            <Text style={pdfStyles.mainText}>
              Content on the right side of the vertical line.
            </Text>
          </View>
        </View>
        <View style={pdfStyles.footer}>
          <Text style={pdfStyles.footerLeft}></Text>
          <View style={pdfStyles.pageText}>
            <Text style={pdfStyles.pageTextLeft}>1</Text>
            <Text style={pdfStyles.pageTextCenter}>/</Text>
            <Text style={pdfStyles.pageTextRight}>20</Text>
          </View>
          <Text style={pdfStyles.footerRight}>2024 Lambda Services.</Text>
        </View>
      </Page>
    </Document>
  );

  // -----------------------------------------------

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <Grid
        templateColumns={{ md: "1fr", lg: "1fr" }}
        templateRows={{ md: "1fr auto", lg: "1fr" }}
        my="26px"
        gap="24px"
      >
        <Box bg="white" borderWidth="1px" borderRadius="lg" p={3} mb={10}>
          <Box p={3}>
            <Center>
              <Heading size="md">시험지 만들기</Heading>
            </Center>
          </Box>
        </Box>

        <Box bg="white" p={3} display="flex" justifyContent="flex-end">
          <Button
            colorScheme="teal"
            variant="outline"
            margin={2}
            marginRight={4}
            onClick={async () => {
              const { blob } = await (
                <BlobProvider document={<MyDocument />} />
              );
              const url = URL.createObjectURL(blob);
              window.open(url, "_blank");
            }}
          >
            pdf 다운로드
          </Button>
        </Box>

        <Grid templateColumns="repeat(2, 1fr)" gap={6}>
          {/* editor region */}
          <QuestionEditor />
          
          <Box
            bg="beige"
            borderWidth="1px"
            borderRadius="lg"
            p={3}
            mb={6}
            height="800px"
          >
            <PDFViewer width="100%" height="100%">
              <MyDocument />
            </PDFViewer>
          </Box>
        </Grid>
      </Grid>
    </Flex>
  );
}
