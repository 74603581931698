import React, { useState, useEffect } from 'react';
import {
  Flex,
  Grid,
  Box,
  VStack,
  Center,
  Button,
  Heading,
  Textarea,
  Input,
  RadioGroup,
  Radio,
  Stack,
  Card,
  CardBody,
  IconButton,
  useToast,
  Text,
} from "@chakra-ui/react";
import { MdOutlineDelete } from "react-icons/md";
import axios from 'axios';
import apiClient from "../../../api/axiosInstance"; // 생성한 Axios 인스턴스를 import

import { useNavigate, useLocation } from 'react-router-dom';

export default function WritePassageDashboard() {
  const [passages, setPassages] = useState([{ title: '', contents: '', category: '문학', questions: [] }]);
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  useEffect(() => {
    if (location.state) {
      const { description, title } = location.state;
      if (title && description) {
        setPassages([{ title, contents: description, category: '문학', questions: [] }]);
      }
    }
  }, [location.state]);

  const handlePassageChange = (index, key, value) => {
    const newPassages = [...passages];
    newPassages[index][key] = value;
    setPassages(newPassages);
  };

  const handleAddPassage = () => {
    setPassages([...passages, { title: '', contents: '', category: '문학', questions: [] }]);
  };

  const handleRemovePassage = (index) => {
    const newPassages = [...passages];
    newPassages.splice(index, 1);
    setPassages(newPassages);
  };

  const handleQuestionChange = (pIndex, qIndex, key, value) => {
    const newPassages = [...passages];
    newPassages[pIndex].questions[qIndex][key] = value;
    setPassages(newPassages);
  };

  const handleAddQuestion = (index) => {
    const newPassages = [...passages];
    newPassages[index].questions.push({ question: '', choice_1: '', choice_2: '', choice_3: '', choice_4: '', choice_5: '', answer: '', explanation: '' });
    setPassages(newPassages);
  };

  const handleRemoveQuestion = (pIndex, qIndex) => {
    const newPassages = [...passages];
    newPassages[pIndex].questions.splice(qIndex, 1);
    setPassages(newPassages);
  };

  const handleSubmit = async () => {
    if (passages.some(passage => !passage.title || !passage.contents || !passage.category || passage.questions.some(question => !question.question))) {
      toast({
        title: "모든 필드를 입력해 주세요.",
        description: "제목, 지문, 카테고리, 문제를 모두 입력해야 합니다.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      return;
    }

    setLoading(true);

    try {
      const response = await apiClient.post(
        `/problem_generator/save_passage_questions/`,
        { passages },
        {
          headers: {
            'content-type': 'application/json'
          }
        }
      );
      console.log(response.data);
      navigate('/problem-bank'); // 수정 후 이동할 페이지로 navigate 설정
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  // Pagination Logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = passages.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(passages.length / itemsPerPage);

  const renderPageNumbers = () => {
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      if (i === 1 || i === totalPages || (i >= currentPage - 1 && i <= currentPage + 1)) {
        pages.push(
          <Button
            key={i}
            onClick={() => setCurrentPage(i)}
            borderRadius="full"
            colorScheme={i === currentPage ? "teal" : "gray"}
            variant={i === currentPage ? "solid" : "outline"}
            size="md"
            mx={1}
          >
            {i}
          </Button>
        );
      } else if (pages[pages.length - 1]?.key !== "...") {
        pages.push(<Text key={`ellipsis-${i}`} mx={1}>...</Text>);
      }
    }
    return pages;
  };

  return (
    <Flex
    flexDirection="column"
    pt={{ base: "120px", md: "75px" }}
    px={{ base: 4, md: 8 }}
  >
    <Grid
      templateColumns={{ md: "1fr", lg: "1fr" }}
      templateRows={{ md: "1fr auto", lg: "1fr" }}
      my="26px"
      gap="24px"
    >
      <Box mb={8}>
        <Heading size="lg" color="teal.600">
        지문 및 문제 입력
        </Heading>
        <Text color="gray.600" mt={2}>
          직접 지문과 문제를 입력하세요 </Text>
      </Box>
      <Box bg="white" borderWidth="1px" borderRadius="xl" p={5} mb={6} boxShadow="md">
          <VStack spacing={6} width="100%">
            {/* Passage and Questions List */}
            {currentItems.map((passage, pIndex) => (
              <Box
                key={pIndex}
                width="100%"
                borderWidth="1px"
                borderRadius="xl"
                p={5}
                mb={6}
                boxShadow="lg"
                bg="gray.50"
                position="relative"
              >
                <Input
                  value={passage.title}
                  onChange={(e) =>
                    handlePassageChange(
                      pIndex + (currentPage - 1) * itemsPerPage,
                      "title",
                      e.target.value
                    )
                  }
                  placeholder="지문 제목을 입력하세요..."
                  mb={4}
                  isDisabled={isLoading}
                  borderRadius="full"
                />
                <Textarea
                  value={passage.contents}
                  onChange={(e) =>
                    handlePassageChange(
                      pIndex + (currentPage - 1) * itemsPerPage,
                      "contents",
                      e.target.value
                    )
                  }
                  placeholder="지문 내용을 입력하세요..."
                  height="200px"
                  resize="vertical"
                  mb={4}
                  isDisabled={isLoading}
                  borderRadius="lg"
                />
                <RadioGroup
                  onChange={(value) =>
                    handlePassageChange(
                      pIndex + (currentPage - 1) * itemsPerPage,
                      "category",
                      value
                    )
                  }
                  value={passage.category}
                  mb={4}
                  isDisabled={isLoading}
                >
                  <Stack
                    direction={{ base: "column", md: "row" }}
                    spacing={5}
                    justify="center"
                  >
                    {["화법", "작문", "언어(문법)", "독서", "문학"].map(
                      (category) => (
                        <Radio
                          key={category}
                          value={category}
                          colorScheme="teal"
                        >
                          {category}
                        </Radio>
                      )
                    )}
                  </Stack>
                </RadioGroup>
                <VStack spacing={4}>
                  {passage.questions.map((question, qIndex) => (
                    <Box
                      key={qIndex}
                      width="100%"
                      borderWidth="1px"
                      borderRadius="xl"
                      p={4}
                      mb={4}
                      bg="white"
                      boxShadow="md"
                      position="relative"
                    >
                      <Textarea
                        value={question.question}
                        onChange={(e) =>
                          handleQuestionChange(
                            pIndex + (currentPage - 1) * itemsPerPage,
                            qIndex,
                            "question",
                            e.target.value
                          )
                        }
                        placeholder="문제를 입력하세요..."
                        mb={4}
                        isDisabled={isLoading}
                        borderRadius="lg"
                      />
                      {[...Array(5)].map((_, index) => (
                        <Input
                          key={`choice_${index + 1}`}
                          value={question[`choice_${index + 1}`]}
                          onChange={(e) =>
                            handleQuestionChange(
                              pIndex + (currentPage - 1) * itemsPerPage,
                              qIndex,
                              `choice_${index + 1}`,
                              e.target.value
                            )
                          }
                          placeholder={`선택지 ${index + 1}`}
                          mb={3}
                          isDisabled={isLoading}
                          borderRadius="full"
                        />
                      ))}
                      <Input
                        value={question.answer}
                        onChange={(e) =>
                          handleQuestionChange(
                            pIndex + (currentPage - 1) * itemsPerPage,
                            qIndex,
                            "answer",
                            e.target.value
                          )
                        }
                        placeholder="정답"
                        mb={4}
                        isDisabled={isLoading}
                        borderRadius="full"
                      />
                      <Textarea
                        value={question.explanation}
                        onChange={(e) =>
                          handleQuestionChange(
                            pIndex + (currentPage - 1) * itemsPerPage,
                            qIndex,
                            "explanation",
                            e.target.value
                          )
                        }
                        placeholder="해설을 입력하세요..."
                        mb={4}
                        isDisabled={isLoading}
                        borderRadius="lg"
                      />
                      <IconButton
                        aria-label="Remove question"
                        icon={<MdOutlineDelete />}
                        position="absolute"
                        bottom="10px"
                        right="10px"
                        onClick={() =>
                          handleRemoveQuestion(
                            pIndex + (currentPage - 1) * itemsPerPage,
                            qIndex
                          )
                        }
                        colorScheme="red"
                        variant="outline"
                        borderRadius="full"
                      />
                    </Box>
                  ))}
                  <Button
                    onClick={() =>
                      handleAddQuestion(pIndex + (currentPage - 1) * itemsPerPage)
                    }
                    colorScheme="teal"
                    isDisabled={isLoading}
                    borderRadius="full"
                  >
                    문제 추가
                  </Button>
                </VStack>
                <IconButton
                  aria-label="Remove passage"
                  icon={<MdOutlineDelete />}
                  position="absolute"
                  bottom="10px"
                  right="10px"
                  onClick={() =>
                    handleRemovePassage(
                      pIndex + (currentPage - 1) * itemsPerPage
                    )
                  }
                  colorScheme="red"
                  variant="outline"
                  borderRadius="full"
                />
              </Box>
            ))}
            <Button
              onClick={handleAddPassage}
              colorScheme="teal"
              isDisabled={isLoading}
              borderRadius="full"
            >
              지문 추가
            </Button>
            <Button
              colorScheme="teal"
              onClick={handleSubmit}
              isLoading={isLoading}
              loadingText="제출 중"
              borderRadius="full"
              width="100%"
            >
              제출
            </Button>
            {/* Pagination Controls */}
            {totalPages > 1 && (
              <Flex justifyContent="center" alignItems="center" mt={6}>
                {renderPageNumbers()}
              </Flex>
            )}
          </VStack>
        </Box>
      </Grid>
    </Flex>
  );
}