import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  Box,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import axios from "axios";

export default function LiteraryWorkModal({ isOpen, onClose, work }) {
  const [contents, setContents] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [dots, setDots] = useState("");

  useEffect(() => {
    let interval;
    if (isOpen && isLoading) {
      setDots(""); // Reset dots when the modal is opened
      interval = setInterval(() => {
        setDots((prev) => prev + "."); // Keep adding dots
      }, 750);
    }

    // Cleanup interval on close
    return () => clearInterval(interval);
  }, [isOpen, isLoading]);

  useEffect(() => {
    if (isOpen && work) {
      setIsLoading(true);
      axios
        .get(
          `${process.env.REACT_APP_BACKEND_API_ENDPOINT}/problem_generator/literary_work_contents/${work.id}/`
        )
        .then((response) => {
          let fetchedContents = response.data.contents;
          setContents(fetchedContents);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching contents:", error);
          setIsLoading(false);
        });
    }
  }, [isOpen, work]);

  const workType = work?.category?.split("_")[1];

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg" isCentered>
      <ModalOverlay />

      {isLoading ? (
        <ModalContent>
          <ModalBody
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="400px"
          >
            <Text fontSize="xl">작품 불러오는 중.{dots}</Text>
          </ModalBody>
        </ModalContent>
      ) : workType === "poetry" ? (
        <ModalContent>
          <ModalBody px={2} pt={2} pb={0} mb={0} maxH="600px" overflowY="auto">
            <Box
              border="1px solid black"
              p={2}
              mb={0}
              textAlign="left"
              whiteSpace="pre-wrap"
            >
              <Text mb={0}>{contents || "작품 내용"}</Text>
              <Text textAlign="right" mt={0}>
                - {work.author}, 「{work.title}」 -
              </Text>
            </Box>
          </ModalBody>
          <ModalFooter px={0} py={0} my={2} justifyContent="center">
            <Button colorScheme="teal" onClick={onClose} width="25%">
              닫기
            </Button>
          </ModalFooter>
        </ModalContent>
      ) : (
        <ModalContent>
          <ModalBody px={2} pt={2} pb={0} mb={0} maxH="600px" overflowY="auto">
            {/* Handle non-poetry content */}
            <Box p={2} mb={0}>
              <Text fontWeight="bold" mb={2}>
                {work.title}
              </Text>
              <Text>{contents || "작품 내용"}</Text>
            </Box>
          </ModalBody>
          <ModalFooter px={0} py={0} my={2} justifyContent="center">
            <Button colorScheme="teal" onClick={onClose} width="25%">
              닫기
            </Button>
          </ModalFooter>
        </ModalContent>
      )}
    </Modal>
  );
}
